import { useEffect, useState } from 'react';
import './estiloAnimarCronometro.css'; // Arquivo CSS para estilização
import { deleteItem } from './imagens';
import NotificadorDeSelecao from './NotificadorDeSelecao';
import gravarDados from './firebase/gravarDados';

import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from './firebase/configuracoes';

const Tabela = ({header, valoresList, dataSelecionada}) => {
        
    const [listaRegistros, setListaRegistros] = useState([]);
    const [visibilidadeNotificador, setVisibilidadeNotificador] = useState({visibilidade:false, key:null});

    useEffect(()=>{
        const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
        if(header === true){
            const db = getDatabase(app);
            const cronometro = ref(db, "DadosUsuarios/" + dadosUsuario.uid + "/Registros");
            onValue(cronometro, (snapshot) => {
                let cronometroList = snapshot.val();
                if(cronometroList){
                    setListaRegistros(Object.entries(cronometroList).filter(([key, lista])=> lista.inicio.includes(dataSelecionada) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado))
                }
            });
        }else{
            if(valoresList.length>0){
                setListaRegistros(valoresList.filter((lista)=> lista.inicio.includes(dataSelecionada) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado))
            }
        }
    },[dataSelecionada, valoresList]);

    function deleteItemFirebase (){
        gravarDados(`Registros/${visibilidadeNotificador.key}`, null);
        setVisibilidadeNotificador({visibilidade:false, key:null});
    }

    const dadosTabela = header ? 
        listaRegistros.map(([key, item]) => (
            <tr key={key}>
                <td>
                    <span className="dot"></span> {`R$ ${item.valorPago}`}
                </td>
                <td>
                    {item.tipoCronometro === "Livre"?`${item.tempoLivreTotal} min` : `${item.tempo}:00 min`}
                </td>
                {
                    header &&
                    <td style={{alignItems:"center", justifyContent:"center"}}>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                            <img onClick={()=>setVisibilidadeNotificador({visibilidade:true, key:key})} className='btn-delete' src={deleteItem} alt='botão deletar'/>
                        </div>
                    </td>
                }
            </tr>
        )) : listaRegistros.map((item, index) => (
            <tr key={index}>
                <td>
                    <span className="dot"></span> {`R$ ${item.valorPago}`}
                </td>
                <td>
                    {item.tipoCronometro === "Livre"?`${item.tempoLivreTotal} min` : `${item.tempo}:00 min`}
                </td>
            </tr>
        ));


    return (
        <>
        <NotificadorDeSelecao btn2Clique={deleteItemFirebase}  btn1Clique={()=>setVisibilidadeNotificador({visibilidade:false, key:null})} visibilidade={visibilidadeNotificador.visibilidade} msg={"Deseja realmente excluir este registro?"} btn1={"Sim"} btn2={"Não"}/>
        <div style={{display:"flex", flexDirection:"column",gap:"20px", alignItems:"center", justifyContent:"start", maxWidth:"400px", width:"95%", height:"calc(100% - 230px)"}} className="valores-por-tempo">
            
            <div style={{padding:"5px",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"5px", width:"100%", backgroundColor:"#4A9947"}}>
                <h3 style={{textAlign:"center", color:"#ffff"}}>Acompanhe os valores</h3>
            </div>

            <div style={{display:"flex", flexDirection:'column', alignItems:"center",width:"100%", overflowY:"auto", scrollbarWidth:"none"}}>
                {
                    listaRegistros.length>0&&
                    <table>
                    <thead>
                        <tr>
                            <th style={{fontWeight:"700"}}>Valor</th>
                            <th style={{fontWeight:"700"}}>Tempo</th>
                            {header && <th style={{fontWeight:"700"}}>Ações</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {dadosTabela}
                    </tbody>
                </table>
                }
            </div>
        </div>
        </>
    );
};

export default Tabela;