import { getDatabase, ref, set } from "firebase/database";
import { app } from "./configuracoes";
import Notificador from "../notificador";
import handleFirebaseError from "./erros";

export default function gravarDados(referenciaTag, dados){
  const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
  if(dadosUsuario){
    const db = getDatabase(app);
    set(ref(db, "DadosUsuarios/" + dadosUsuario.uid +"/"+ referenciaTag), dados)
      .then(dados=>Notificador("Dados salvos com sucesso", "", "success"))
      .catch(error=>Notificador("Algo deu errado", handleFirebaseError(error), "warning"))
  }
};