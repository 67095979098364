import { add } from "./imagens"
export default function VazioCronometros ({eventClique, notificacao, visibilidade}){
    
    return(
        visibilidade&&
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:"50%"}}>
            <div style={{display:"flex", flexDirection:"column",width:"90%", height:"100%",justifyContent:"center", alignItems:"center", gap:"5px"}}>
                <h4 style={{pointerEvents:"none", color:"#ffff", fontWeight:"500", textAlign:"center"}}>{notificacao}</h4>
                <img onClick={eventClique} style={{width:"25px", height:"25px"}} src={add}/>
            </div>
        </div>
    )
};