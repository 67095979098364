import loginGoogle from "./firebase/loginGoogle";
import { google, facebook } from "./imagens";

export default function LoginMetodo ({titulo = "Ou escolha um método para fazer login", callback}){

    return(
        <div style={{width:"100%",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <h2 style={{margin:"15px",fontSize:"14px", fontWeight:"500", color:"rgb(165, 165, 170)"}}>{titulo}</h2>
            <div style={{width:"100%",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"10px"}}>
                <BtnClique imgem={google} nome={"Google"} clique={()=>loginGoogle(callback)}/>
                <BtnClique imgem={facebook} nome={"Facebook"}/>
            </div>
        </div>
    )
};

function BtnClique ({nome, clique, imgem}){
    return(
        <div onClick={clique} style={{cursor:"pointer",boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",padding:"5px",width:"30%",borderRadius:"10px",gap:"5px",display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
            <img style={{width:"25px", height:"25px"}} src={imgem} alt="google"/>
            <p style={{pointerEvents:"none",fontSize:"14px", fontWeight:"600", width:"50%"}}>{nome}</p>
        </div>
    )
};