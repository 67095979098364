
export default function NotificadorDeSelecao ({visibilidade, msg, btn1, btn2, btn1Clique, btn2Clique, personalizar = false}){
    return(
        visibilidade&&
            <div style={{backgroundColor:"#00000096", position:"absolute", zIndex:1000,display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:personalizar?"100%" : window.innerHeight- 70}}>
                <div style={{gap:"15px",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px",borderRadius:"10px",backgroundColor:"#ffff",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", padding:"15px", width:"300px"}}>
                    <p style={{fontSize:"20px", fontWeight:"500"}}>{msg}</p>
                    <div style={{display:"flex", flexDirection:"row", gap:"10px", width:"100%", justifyContent:"center", alignItems:"center"}}>
                        <div onClick={btn2Clique}  style={{cursor:"pointer",gap:"5px",backgroundColor:"#FF3C3C", padding:"5px", height:"35px", borderRadius:"5px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"40%"}}>
                            <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>{btn1}</h6>
                        </div>

                        <div onClick={btn1Clique} style={{cursor:"pointer",gap:"5px",backgroundColor:"#007E7A", padding:"5px", height:"35px", borderRadius:"5px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"40%"}}>
                            <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>{btn2}</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
};