import { useEffect, useState, useRef } from "react";
import { icone_crianca, cash, relogio, iniciar, pause, moneyBag } from "./imagens";
import '../componentes/estiloAnimarCronometro.css';
import useWebSocket from 'react-use-websocket';


const getStoredTime = (defaultTime, storageName) => {
  const storedTime = localStorage.getItem(storageName);
  return storedTime ? parseInt(storedTime, 10) : defaultTime;
};

const getStoredIsRunning = (storageName) => {
  const storedIsRunning = localStorage.getItem(storageName);
  return storedIsRunning ? JSON.parse(storedIsRunning) : false;
};

const getStoredStartTime = (storageName) => {
  const storedStartTime = localStorage.getItem(storageName);
  return storedStartTime ? parseInt(storedStartTime, 10) : Date.now();
};

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export default function ItemCronometro({statusRede, abrirMenu, concluido, keyId, tempoLivre, duracao, segundoTempoLivre, start, objetoCronometro}) {

  const [tempo, setTempo] = useState(getStoredTime(tempoLivre ? 0 : duracao, `remainingTime-${objetoCronometro.codigo}`));
  const [isRunning, setIsRunning] = useState(getStoredIsRunning(`isRunning-${objetoCronometro.codigo}`));
  const [tempoFormat, setTempoFormat] = useState(formatTime(tempo));
  const [pauseTime, setPauseTime] = useState(null);
  const animationRef = useRef(null);
  const startTimeRef = useRef(getStoredStartTime(`startTime-${objetoCronometro.codigo}`));



  function removerLocalStorage() {
    localStorage.removeItem(`remainingTime-${objetoCronometro.codigo}`);
    localStorage.removeItem(`isRunning-${objetoCronometro.codigo}`);
    localStorage.removeItem(`startTime-${objetoCronometro.codigo}`);
  };

  const meio = "prod";

  const url = meio === "development"?"ws://localhost:8080":"wss://app.brinquedometro.com.br/ws/";
  const [socketUrl] = useState(url);

  const { sendMessage } = useWebSocket(socketUrl, {
      onMessage: (event) => {
          const dados = JSON.parse(event.data);
          console.log('Mensagem do servidor:', dados);
      },
      shouldReconnect: (closeEvent) => true, // Tentar reconectar em caso de falha
  });

const handleSendCommand = (command) => {
  if(statusRede){

      const data = {
        command: command,
        id: objetoCronometro.codigo,
        milliseconds: tempoLivre?1000:parseInt(objetoCronometro.duracao, 10),
        tipoCronometro:tempoLivre?"livre":"decrescente",
        cronometro:objetoCronometro
    };
    sendMessage(JSON.stringify(data));
  }else{
    console.log("Sem conexão de rede!");
  }
};


  useEffect(() => {
    if (tempo < 0) {
      setIsRunning(false);
      concluido(prev => {
        const newPrev = [...prev];
        handleSendCommand("cancel");
        newPrev[keyId].concluido = true;
        return newPrev;
      });
      removerLocalStorage();
    }
  }, [tempo, keyId]);

  const updateTempo = () => {
    if (isRunning) {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTimeRef.current;
      const newTempo = tempoLivre ? elapsedTime : duracao - elapsedTime;
      setTempo(newTempo);
      setTempoFormat(formatTime(newTempo));
      localStorage.setItem(`remainingTime-${objetoCronometro.codigo}`, newTempo);

      // Continue the animation
      animationRef.current = requestAnimationFrame(updateTempo);
    }
  };

  useEffect(() => {
    if (isRunning) {
      startTimeRef.current = getStoredStartTime(`startTime-${objetoCronometro.codigo}`);
      animationRef.current = requestAnimationFrame(updateTempo);
    } else {
      cancelAnimationFrame(animationRef.current);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [isRunning]);

  useEffect(() => {
    localStorage.setItem(`isRunning-${objetoCronometro.codigo}`, isRunning);
    if (isRunning) {
      if (pauseTime !== null) {
        const currentTime = Date.now();
        const newStartTime = currentTime - pauseTime;
        localStorage.setItem(`startTime-${objetoCronometro.codigo}`, newStartTime);
        setPauseTime(null);
      } else {
        localStorage.setItem(`startTime-${objetoCronometro.codigo}`, Date.now() - (tempoLivre ? tempo : duracao - tempo));
      }
    } else {
      setPauseTime(tempo);
    }
    segundoTempoLivre(keyId, parseInt((tempo / 1000).toFixed(0)));
  }, [isRunning, tempo, pauseTime, keyId, objetoCronometro.codigo]);

  const handleStart = () => {
    if (!isRunning) {
      start(keyId);
      console.log("despausado");
      handleSendCommand("start");
      const pausedAt = parseInt(localStorage.getItem(`pausedAt-${objetoCronometro.codigo}`), 10);
      if (pausedAt) {
        const pauseDuration = Date.now() - pausedAt;
        const startTime = getStoredStartTime(`startTime-${objetoCronometro.codigo}`) + pauseDuration;
        localStorage.setItem(`startTime-${objetoCronometro.codigo}`, startTime);
        localStorage.removeItem(`pausedAt-${objetoCronometro.codigo}`);
      }
    } else {
      start(keyId);
      console.log("pausado");
      handleSendCommand("start");
      localStorage.setItem(`pausedAt-${objetoCronometro.codigo}`, Date.now());
    }
    setIsRunning(!isRunning);
  };

  const estiloH3 = { pointerEvents: "none", color: "#007E7A", fontSize: "19px", fontWeight: "700", margin: "0", padding: "0", height: "28px", textAlign: "start" };

  return (
    <div style={{ padding: "4px" }}>
      <div style={{ animation: objetoCronometro.concluido ? "vibrate-1 0.3s linear infinite both" : "", WebkitAnimation: objetoCronometro.concluido ? "vibrate-1 0.3s linear infinite both" : "", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: "pointer", gap: "5px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "179px", height: "190px", backgroundColor: "#ffff", borderRadius: "10px", overflow: "hidden" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", width: "162px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={icone_crianca} alt="Ícone Criança" />
          <h3 style={estiloH3}>{objetoCronometro.crianca}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", width: "162px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={moneyBag} alt="QR Code" />
          <h3 style={estiloH3}>{objetoCronometro.pagamento}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", width: "162px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={cash} alt="Ícone Dinheiro" />
          <h3 style={estiloH3}>{tempoLivre ? `$ ${String(objetoCronometro.valorPago).replace(".", ",")}` : `$ ${objetoCronometro.valorPago},00`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", width: "162px", height: "30px" }}>
          <img style={{ width: "35px", height: "35px" }} src={relogio} alt="Ícone Relógio" />
          <h3 style={estiloH3}>{objetoCronometro.concluido ? "Concluido" : tempoFormat}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "30px", width: "162px", height: "30px" }}>
          <button onClick={abrirMenu} style={{ cursor: "pointer", backgroundColor: "#007E7A", border: "none", width: "70px", height: "27px", borderRadius: "5px", color: "#ffff", fontSize: "18px", fontWeight: "700", margin: "0", textAlign: "center" }}>Menu</button>
          <img onClick={objetoCronometro.concluido ? null : handleStart} style={{ width: "35px", height: "35px", cursor: "pointer" }} src={isRunning ? pause : iniciar} alt="Botão Iniciar/Pausar" />
        </div>
      </div>
    </div>
  );
}