import '../configuracao/Configuracao.css';
import React from 'react';
import { HeaderSuperior } from "../../componentes/painel_header";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { emailContato, maleUser, telefone, plano, calendario } from '../../componentes/imagens';
import Planos from '../../componentes/Planos';
import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from '../../componentes/firebase/configuracoes';
import NotificadorDeSelecao from '../../componentes/NotificadorDeSelecao';
import excluirUsuario from '../../componentes/firebase/excluirUsuario';
import dataText from '../../componentes/dataComoText';

export default function Configuracao (){
    const [dados, setDados] = React.useState({dadosEmail:JSON.parse(localStorage.getItem("usuarioLogado"))});
    const [planos, setPlanos] = React.useState([]);
    const [visibilidadePlanos, setVisibilidadePlanos] = React.useState(false);
    const [visibilidadeMenuPlano, setVisibilidadeMenuPlano] = React.useState(false);
    const [visibilidadeExcluirConta, setVisibilidadeExcluirConta] = React.useState(false);


    const navigate = useNavigate();

    React.useEffect(()=>{
        const db = getDatabase(app);
            const dbConta = ref(db,"Usuarios/" + dados.dadosEmail.uid);
            onValue(dbConta, (snapshot) => {
                let conta = snapshot.val();
                console.log(conta)
                setDados(prev =>{
                    prev = {...prev, usuario:{...conta}}
                    return prev;
                });
            });

            const dbPlanos = ref(db,"ConfiguracoesGlobais/Planos");
            onValue(dbPlanos, (snapshot) => {
                let planos = snapshot.val();
                setPlanos([...planos]);
            });

            const dbMenuPlano = ref(db,"ConfiguracoesGlobais/MenuPlano");
            onValue(dbMenuPlano, (snapshot) => {
                let planoMenu = snapshot.val();
                setVisibilidadeMenuPlano(planoMenu.Ativo);
            });

    },[]);

    return (
        <>        
            {visibilidadePlanos && <Planos close={()=>setVisibilidadePlanos(false)} planosLista = {planos}/>}
            <NotificadorDeSelecao personalizar={true} visibilidade={visibilidadeExcluirConta} btn1Clique={()=>setVisibilidadeExcluirConta(false)} btn2Clique={()=>excluirUsuario(navigate)} btn1={"Sim"} btn2={"Cancelar"} msg={"Ao excluir sua conta, todos os dados associados a ela serão permanentemente removidos de nossos servidores, e o acesso ao aplicativo será imediatamente revogado após a confirmação. Deseja continuar?"}/>
            <div className='principal'>
            <HeaderSuperior titulo={"Minha conta"} eventClique={()=>navigate("/home")}/>
                <div className='conteudo'>
                <div className='card'>
                    <h1>Informações do usuário</h1>
                    <InputTexto value={dados.dadosEmail.providerData !== undefined ? dados.dadosEmail.providerData[0].displayName !== null? dados.dadosEmail.providerData[0].displayName : dados.usuario !== undefined ? dados.usuario.Nome : "":""} cardNome={"Nome de usuário"} image={maleUser}/>
                    <InputTexto value={dados.dadosEmail.email} cardNome={"Endereço de e-mail"} image={emailContato}/>
                    <InputTexto value={dados.usuario !== undefined ? dados.usuario.telefone : ""} cardNome={"Número de telefone"} image={telefone}/>
                    <BtnEventos clique={()=>setVisibilidadeExcluirConta(true)} titulo={"Excluir conta"}/>
                </div>
                {visibilidadeMenuPlano&&
                    <div className='card'>
                        <h1>Meu plano</h1>
                        <InputTexto value={dados.usuario !== undefined ? dados.usuario.Plano : ""} cardNome={"Plano atual"} image={plano}/>
                        <InputTexto value={dados.usuario !== undefined ? dados.usuario.EndPlano  : ""} cardNome={"Valido até"} image={calendario}/>
                        <BtnEventos clique={()=>setVisibilidadePlanos(true)} titulo={"Atulizar plano"}/>
                    </div>
                }
                </div>
            </div>
        </>
    )
};


function InputTexto ({value = "", cardNome, image}){
    return(
        <div className='card-input'>
            <legend>{cardNome + ":"}</legend>
            <div className='input-card'>
                <img style={{width:"35px", height:"35px"}} src={image} alt={cardNome}/>
                <input placeholder={cardNome} value={value} disabled/>
            </div>
        </div>
    )
};

function BtnEventos ({titulo, clique}){
    return(
        <div className='div-eventos'>
            <button className='btn-eventos' onClick={clique}>{titulo}</button>
        </div>
    )
}

InputTexto.prototype={
    value: PropTypes.string,
    cardNome: PropTypes.string,
    image: PropTypes.string
};
