import {app} from './configuracoes';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import handleFirebaseError from './erros';
import Notificador from '../notificador';
import primeiroAcesso from './primeiroLogin';

export default function cadastreSe(email, password){
  return new Promise ((resolve, reject)=>{
    const auth = getAuth(app);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(user)
            .then(()=>{
              primeiroAcesso(user.uid, user.email, user.displayName);
              resolve(`Um e-mail de verificação foi enviado para ${user.email}. Por favor, abra seu e-mail e clique no link de verificação. Após isso, seu acesso estará liberado.`);
            })
            .catch((error)=>{
                Notificador("Algo deu errado",handleFirebaseError(error), "warning");
            });
      })
      .catch((error) => {
        reject(()=>Notificador("Algo deu errado",handleFirebaseError(error), "warning"));
      });
  })
};

