import '../home/home.css';
import { icone, cash_hist, briquedo, grafico, relogio2, config, help } from '../../componentes/imagens';
import BotaoHome from '../../componentes/BotaoHome';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from '../../componentes/firebase/configuracoes';
import { useEffect, useState } from 'react';
import refreshDadosSendFirebase from '../../componentes/refreshDadosSendFirebase';
import Notificador from '../../componentes/notificador';

export default function Home (){
    const [usuario, setUsuario] = useState();
    const [animarBtn, setAnimarBtn] = useState(false);

    useEffect(()=>{
        const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
        setUsuario(dadosUsuario);
        const db = getDatabase(app);
        const starCountRef = ref(db, "DadosUsuarios/" + dadosUsuario.uid + "/TemposEvalores");
        onValue(starCountRef, (snapshot) => {
            let tempos = snapshot.val();
            localStorage.setItem("tempos",JSON.stringify(tempos));
        });

        const brinquedos = ref(db, "DadosUsuarios/" + dadosUsuario.uid + "/Brinquedos");
        onValue(brinquedos, (snapshot) => {
            let brinquedosList = snapshot.val();
            localStorage.setItem("brinquedos",JSON.stringify(brinquedosList));
        });
        refreshDadosSendFirebase() 
    },[]);

    const navigate = useNavigate();

    return(
        <div className='div-principal-home'>
            <header>
                <h1 style={{pointerEvents:"none",color:"#ffff", fontSize:"17px", fontWeight:"700"}}>{usuario?usuario.providerData[0].displayName?usuario.providerData[0].displayName:usuario.email : ""}</h1>
                <img style={{width:"35px", height:"35px", borderRadius:"100%"}} src ={usuario? usuario.providerData[0].photoURL?usuario.providerData[0].photoURL : icone : ""} alt="foto do usuario"/>
            </header>

            <div className='div-menus-btn'>
                <div className='div-btns'>
                    <BotaoHome
                        image={icone}
                        nome={"Cronômetro"}
                        clique={()=>{
                            const brinquedos = localStorage.getItem("brinquedos");
                            if(brinquedos !== "null"){
                                navigate("/cronometro");
                            }else{
                                Notificador("Cadastre os brinquedos", "Antes de prosseguir cadastre seus brinquedos no menu Cadastrar brinquedo", "warning");
                                setAnimarBtn(true);
                            }
                        }}
                    />
                    <BotaoHome
                        image={cash_hist}
                        nome={"Histórico de ganhos"}
                        clique={()=>navigate("/ganhos")}
                    />
                </div>
                <div className='div-btns'>
                    <BotaoHome
                        image={grafico}
                        nome={"Dashboard de ganhos"}
                        clique={()=>navigate("/painel")}
                    />
                    <BotaoHome
                        image={briquedo}
                        animar={animarBtn}
                        nome={"Cadastrar brinquedo"}
                        clique={()=>navigate("/tempo",{state:{titulo:"Cadastrar brinquedo", localFirebase:"Brinquedos"}})}
                    />
                </div>
                <div className='div-btns'>
                    <BotaoHome
                        image={relogio2}
                        nome={"Cadastrar tempos"}
                        clique={()=>navigate("/tempo",{state:{titulo:"Cadastrar tempos", localFirebase:"TemposEvalores"}})}
                    />
                    <BotaoHome
                        image={config}
                        nome={"Minha conta"}
                        clique={()=>navigate("/conta")}
                    />
                </div>
                <div className='div-btns'>
                    <BotaoHome
                        image={help}
                        nome={"Ajuda"}
                        clique={()=>navigate("/ajuda")}
                    />
                    
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:"30px", width:"100%"}}>
                <footer style={{color:"#ffff", fontSize:"15px"}}>&copy; {new Date().getFullYear()} Brinquedômetro</footer>
            </div>
        </div>
    )
}