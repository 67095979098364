export default function dataText(dataDate) {
    function formatData (dataV){
        let result = dataV;
        if(dataV<10){
            result = `0${dataV}`;
        }
        return result;
    }

    let data = dataDate;
    data = `${formatData(data.getDate())}/${formatData(data.getMonth()+1)}/${data.getFullYear()}`;
    return data
};