import GraficoPizza from "./graficos/GraficoPizza";
import { cores } from "../../componentes/imagens";
import Card from "./graficos/Cards";
import { HeaderSuperior } from "../../componentes/painel_header";
import { useNavigate } from "react-router-dom";
import { useEffect, useState} from "react";
import Calendario from "./graficos/Calendario";
import { obterTodosRegistros } from "../../componentes/firebase/obterDadosUmaVez";
import Notificador from "../../componentes/notificador";
import handleFirebaseError from "../../componentes/firebase/erros";
import { GanhosXVendaPorDia } from "./graficos/tratamentoDados";
import GraficoCombinado from "./graficos/GraficoCombinado";
import ActiveIndicador from "../../componentes/Activeindicador";
import CardDate from "../../componentes/CardDate";
import '../dashboard/estilos.css';

export default function Dashboard(){
    const [visibilidadeCalendario, setVisibilidadeCalendario] = useState(false);
    const [listaBaixada, setListaBaixada] = useState();
    const [dadosTratados, setDadosTratados] = useState();
    const [activeIndicador, setActiveIndicador] =  useState(true);
    const [dataSelect, setDataSelect] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'init'
    });
 
    const navigate = useNavigate();

    async function dadosOnline (){
      try{
          const dados = await obterTodosRegistros("Registros");
          const lista = Object.entries(dados).map(([key, dados])=>{
              return dados
          })
          setListaBaixada(lista);
      }catch(error){
          Notificador(handleFirebaseError(error),"", "warning");
      }
  };

  useEffect(()=>{

    if(listaBaixada){
      ajustarGraficosIntervalo(dataSelect, dataSelect.key === "init"?true:false);
    }
  }, [listaBaixada, dataSelect]);

  useEffect(()=>{
    if(dadosTratados){
      setActiveIndicador(false);
    }
  }, [dadosTratados]);

  function ajustarGraficosIntervalo (intervalo, inicializacao){
    const dados = GanhosXVendaPorDia(listaBaixada, intervalo, inicializacao);
    setDadosTratados(dados);

    if(dados === undefined){
      setActiveIndicador(false);
      Notificador("Sem dados para exibir!","","warning");
    }
  };

  useEffect(()=>{
    dadosOnline();
  },[]);

    return(
        <div style={{gap:"10px",display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"flex-start",width:"100%", height:"100%", backgroundColor:cores.verderv}}>
          <ActiveIndicador cor={"#000000b0"} visibilidade={activeIndicador}/>
            {visibilidadeCalendario&&<Calendario setDataInterva={setDataSelect} ocultar={()=>setVisibilidadeCalendario(false)}/>}
            <HeaderSuperior child={<CardDate cliqueCal={()=>setVisibilidadeCalendario(true)} valor={dataSelect}/>} data={dataSelect} abrirCalendario={()=>setVisibilidadeCalendario(true)} eventClique={()=>navigate("/home")} titulo={"Dashboard de Análises"}/>
            <div style={{width:"91.5%", display:"flex",flexDirection:"column", alignItems:"flex-start", justifyContent:"center"}}>
                <div style={{overflowX:"auto",scrollbarWidth:"none",width:"100%", marginTop:"65px",display:"flex", flexDirection:"row", alignItems:"center", gap:"20px"}}>
                    <Card datas={dataSelect} moeda={true} titulo={"Total de ganhos"} cor={"#28A745"} valor={dadosTratados?dadosTratados.cards.totalGanhos:0} icone={<DollarSignIcon cor={"#ffff"}/>}/>
                    <Card datas={dataSelect}  moeda={true} titulo={"Média de ganhos por dia"} cor={"#FFC107"} valor={dadosTratados?dadosTratados.cards.mediaGanhosDia:0} icone={<DollarSignIcon cor={"#ffff"}/>}/>
                    <Card datas={dataSelect}  titulo={"Total de vendas"} cor={"#007BFF"} valor={`${dadosTratados?dadosTratados.cards.totalVendas:0}`} icone={<ShoppingCartIcon/>}/>
                    <Card datas={dataSelect}  titulo={"Média de vendas por dia"} cor={"rgb(244 207 40)"} valor={`${dadosTratados?dadosTratados.cards.mediaVendasDia:0}`} icone={<ShoppingCartIcon/>}/>
                </div>
            </div>
            <div style={{scrollbarWidth:"none",gap:"20px",display:"flex",alignItems:"start", justifyContent:"center",width:"100%", height:"100%", flexWrap:"wrap", overflowY:"auto"}}>
                <GraficoPizza id={"1"} dadosList={dadosTratados?dadosTratados.brinquedos:[]} titulo={"Brinquedos mais utilizados"}/>
                <GraficoPizza id={"2"} dadosList={dadosTratados?dadosTratados.diasDaSemana:[]} titulo={"Dias mais lucrativos"}/>
                <GraficoCombinado id={"3"} tituloY1={"Ganhos"} cor1={"rgb(40, 167, 69)"} tituloY2={"Vendas"} cor2={"rgb(0, 123, 255)"} dadosList={dadosTratados?dadosTratados.ganhosXDia:[]} titulo={"Ganhos por dia x Vendas por dia"}/>
                <GraficoCombinado id={"4"}  tituloY1={"Ganhos"} cor1={"rgb(40, 167, 69)"} tituloY2={"Vendas"} cor2={"rgb(0, 123, 255)"} dadosList={dadosTratados?dadosTratados.ganhosXMes:[]} titulo={"Ganhos por mês x Vendas por mês"}/>
                <GraficoCombinado id={"5"} tituloY1={"Ganhos"} cor1={"rgb(40, 167, 69)"} tituloY2={"Vendas"} cor2={"rgb(0, 123, 255)"} dadosList={dadosTratados?dadosTratados.ganhosXAno:[]} titulo={"Ganhos por ano x Vendas por ano"}/>
            </div>
        </div>
    )
}

function DollarSignIcon({cor}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke={cor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="12" x2="12" y1="2" y2="22" />
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
      </svg>
    )
  };

  function CalendarIcon({cor = "#727272", clique}) {
    return (
      <svg
        onClick={clique}
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke={cor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 2v4" />
        <path d="M16 2v4" />
        <rect width="18" height="18" x="3" y="4" rx="2" />
        <path d="M3 10h18" />
      </svg>
    )
  }

export function ShoppingCartIcon(){
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="30" 
      height="30" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="#ffff" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className="lucide lucide-shopping-cart h-8 w-8 mr-4"
    >
      <circle cx="8" cy="21" r="1"></circle>
      <circle cx="19" cy="21" r="1"></circle>
      <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
    </svg>
  );
};


