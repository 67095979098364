import QRCode from "qrcode.react";

export default function GerarQrCode({valorUrl}){
    return(
        <QRCode
            value={valorUrl}
            size={256}
            bgColor="#ffffff"
            fgColor="#000000"
            renderAs="canvas"
        />
    )
};