import {app} from './configuracoes';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import handleFirebaseError from './erros';
import Notificador from '../notificador';

export default function loginConta(email, senha, setActiveIndicador, setnotificacaoPerson){
    const auth = getAuth(app);
    setActiveIndicador(true);
    return new Promise((resolve, reject)=>{
        signInWithEmailAndPassword(auth, email, senha)
        .then((resultado)=>{
            if(resultado.user.emailVerified){
                localStorage.setItem("usuarioLogado",JSON.stringify(resultado.user));
                setActiveIndicador(false);
                resolve(true);
            }else{
                setActiveIndicador(false);
                setnotificacaoPerson(`Antes de acessar você precisa validar seu email ${email}. Por favor, abra seu e-mail e clique no link de verificação.`)
            }
        })
        .catch((error)=>{
            setActiveIndicador(false);
            Notificador("Algo deu errado", handleFirebaseError(error), "warning");
            if(error.code === "auth/network-request-failed"){
                reject(false);
            };
        })
    })
};

