import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "./configuracoes";
import handleFirebaseError from "./erros";
import Notificador from "../notificador";
import primeiroAcesso from "./primeiroLogin";
import telemetria from "./telemetria";

export default function loginGoogle (callbackFuncao){
        // Inicializa o serviço de autenticação
    const auth = getAuth(app);

    // Inicializa o provedor do Google
    const provider = new GoogleAuthProvider();

    // Inicia o processo de login com o Google
    signInWithPopup(auth, provider)
    .then((result) => {
        // Recupera o token de acesso do Google para usar em outras APIs do Google
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // Informações do usuário autenticado
        const user = result.user;

        // IdP (Identity Provider) data pode ser acessada com getAdditionalUserInfo(result)
        // console.log("Usuário logado:", user);
        // console.log("Token de acesso:", token);
        localStorage.setItem("usuarioLogado",JSON.stringify(user));
        callbackFuncao("/home");
        Notificador("Seja bem-vindo!", "", "success");
        telemetria({msg:"login efetuado", data:Date.now()});
        primeiroAcesso(user.uid, user.email, user.displayName);
    })
    .catch((error) => {
        // Tratamento de erros
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.customData?.email; // Verifica se o email está disponível
        // const credential = GoogleAuthProvider.credentialFromError(error);

        // console.error("Erro durante o login:", errorMessage, errorCode);
        // console.error("Email associado ao erro:", email);

        Notificador(handleFirebaseError(error), "", "warning");
    });
}
