import {relogio, qrcodeScan, pause } from "./imagens";
import GerarQrCode from "./QrCodeGerador";

export default function FormDetalhesCronometro({visibilidade, cronometro, setVisibilidade, finalizadoClique, cancelarClique}){

    return(
        visibilidade&&
        <div style={{width:"100%",height:window.innerHeight - 55, backgroundColor:"#00000096", position:"absolute", zIndex:361, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>

            <div style={{overflowY:"auto", boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", gap:"10px",overflow:"hidden",borderRadius:"12px", width:"350px",  backgroundColor:"#ffff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div style={{width:"100%", height:"31px", backgroundColor:"#007E7A", border:"none", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    <header style={{textAlign:"center",color:"#ffff", fontWeight:"500", fontSize:"18px", margin:"0"}}>Informações do cronômetro</header>
                    <span onClick={setVisibilidade} style={{color:"red", fontSize:"22px", fontWeight:"700", width:"40px",cursor:"pointer"}}>X</span>
                </div>

                <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", padding:"2px"}}>
                    <img src={qrcodeScan} alt="icone input" style={{width:"40px", height:"40px"}} />   
                    <p style={{fontSize:"15px"}}>Compartilhe o crônometro com seu cliente escaneando o código abaixo.</p>
                </div>

                <div style={{display:"flex",flexDirection:"column", width:"100%", alignItems:"center", justifyContent:"center"}}>
                    <GerarQrCode valorUrl={`https://app.brinquedometro.com.br/time?id=${cronometro.codigo}&v=${JSON.parse(localStorage.getItem("usuarioLogado")).uid}`}/>
                </div>

                <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"5px", padding:"2px", alignItems:"center"}}>
                    <img src={relogio} alt="icone input" style={{width:"25px", height:"25px"}} />
                    <input disabled value={`Inicio: ${cronometro.inicio}`} type={"text"} placeholder={"Inicio"} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
                </div>

                <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"5px", padding:"2px", alignItems:"center"}}>
                    <img src={relogio} alt="icone input" style={{width:"25px", height:"25px"}} />
                    <input  disabled value={`Fim previsto: ${cronometro.previsaoFim}`}  type={"text"} placeholder={"Fim"} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
                </div>
                <div style={{display:"flex", flexDirection:"row", gap:"10px", width:"100%", justifyContent:"center", alignItems:"center", height:"80px"}}>

                    <div onClick={cancelarClique}  style={{cursor:"pointer",gap:"5px",backgroundColor:"#FF3C3C", padding:"5px", height:"35px", borderRadius:"5px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"40%"}}>
                        <img src={pause} alt="icone input" style={{width:"30px", height:"30px"}} />
                        <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Cancelar</h6>
                    </div>

                    <div onClick={finalizadoClique} style={{cursor:"pointer",gap:"5px",backgroundColor:"#007E7A", padding:"5px", height:"35px", borderRadius:"5px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"40%"}}>
                        <img src={relogio} alt="icone input" style={{width:"30px", height:"30px"}} />
                        <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Concluido</h6>
                    </div>
                </div>
            </div>
    </div>
    )
};
