
export default function handleFirebaseError(error) {
    let result;
    switch (error.code) {
      case 'auth/network-request-failed':
        result = "Você esta sem acesso a internet";
        break
      case 'auth/invalid-email':
        result='O e-mail fornecido não é válido.';
        break;
      case 'auth/user-disabled':
        result='O usuário correspondente à credencial fornecida foi desativado.';
        break;
      case 'auth/user-not-found':
        result='Não há usuário registrado com o e-mail fornecido.';
        break;
      case 'auth/wrong-password':
        result='A senha fornecida não é válida para o e-mail do usuário.';
        break;
      case 'database/permission-denied':
        result='O cliente não tem permissão para acessar o caminho especificado.';
        break;
      case 'firestore/permission-denied':
        result='O cliente não tem permissão para acessar o recurso especificado.';
        break;
      case 'storage/object-not-found':
        result='O objeto especificado não foi encontrado.';
        break;
      case 'app/no-app':
        result='Nenhum aplicativo do Firebase foi inicializado.';
        break;
      case 'auth/invalid-credential':
        result='E-mail ou senha fornecidos são inválidos.';
        break;
        case 'auth/email-already-in-use':
          result ='Este e-mail já esta cadastrado.';
          break;
      case 'auth/too-many-requests':
        result='O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login fracassadas. Você pode restaurá-lo imediatamente redefinindo sua senha ou pode tentar novamente mais tarde.';
        break;
      default:
        result='Ocorreu um erro desconhecido: ' +  error;
    }
    return result;
  };