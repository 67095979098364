import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { isMobile } from 'react-device-detect';
import '../graficos/calendario.css';

const configuracoesGrafico = {          
  series: [],
  options: {
    chart: {
      width: 400,
      type: 'pie',
    },
    labels: [],
    responsive: [{
      breakpoint: 300,
      options: {
        chart: {
          width: 300
        }
      }
    }],
    legend: {
      position: 'bottom', // Posição da legenda (top, bottom, left, right)
      labels: {
        useSeriesColors: false // Usa as cores das fatias para os rótulos da legenda
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value,w) => {
          // Pega o nome da série através dos labels (w.globals.labels)
          const label = w.globals.labels[0];
          // Condiciona o formato com base no nome da série
          if (label === "Domingo") {
            // Se for "Ganhos", formata como moeda (R$)
            return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          } else {
            // Se for outro tipo, formata como unidades
            return `${value} unid.`;
          }
        }
      }
    }
  }
};

export default function GraficoPizza({ dadosList, titulo, id }) {
  const [graficoConfig, setGraficoConfig] = useState({ ...configuracoesGrafico });

  useEffect(() => {
    // Verifica se dadosList tem a estrutura correta
    if (dadosList && Array.isArray(dadosList.valores) && Array.isArray(dadosList.nomes)) {
      setGraficoConfig(prev => ({
        ...prev,
        series: dadosList.valores,
        options: {
          ...prev.options, // Usa o estado anterior ao invés de recriar toda a estrutura
          labels: dadosList.nomes
        }
      }));
    }
  }, [dadosList]); 

  return (
    <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"flex-start",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      backgroundColor: "#ffff",
      minWidth: isMobile ? window.innerWidth - 30 : "320px",
      width: "45%",
      borderRadius: "10px",
      paddingBottom:"15px"
    }}
    id={id}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <h3 style={{ margin: "2px", textAlign: "center", color:"rgb(106, 106, 106)"}}>{titulo}</h3>
      </div>
      <Chart options={graficoConfig.options} series={graficoConfig.series} type="pie" width={isMobile ? window.innerWidth - 30 : 500} />
    </div>
  );
}
