import dataText from "./dataComoText";
import { isMobile } from "react-device-detect";
import React from "react";

const estilo = {
    display:"flex",
    flexDirection:"row", 
    gap:"10px", 
    alignItems:"center", 
    justifyContent:"center", 
    fontSize:"14px",
    cursor:"pointer"
};

export default function CardDate ({valor, cliqueCal}){

    return (
      !isMobile?
        <div style={{...estilo, paddingLeft:"10px", paddingRight:"10px"}}>
            <div style={estilo}>
                <CalendarIcon clique={cliqueCal} />
                <p onClick={cliqueCal} style={estilo}>{dataText(valor.startDate)}</p>
            </div>
            <p style={estilo}>até</p>
            <div style={estilo}>
                <CalendarIcon clique={cliqueCal} />
                <p onClick={cliqueCal} style={estilo}>{dataText(valor.endDate)}</p>
            </div>
        </div> : <CalendarIcon clique={cliqueCal}/>
    )
};

function CalendarIcon({cor = "#727272", clique}) {
    return (
      <svg
        onClick={clique}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke={cor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 2v4" />
        <path d="M16 2v4" />
        <rect width="18" height="18" x="3" y="4" rx="2" />
        <path d="M3 10h18" />
      </svg>
    )
  };