import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { isMobile } from 'react-device-detect';
import './../estilos.css';

const initialChartData = {
  series: [{
    name: 'Ganhos por dia',
    type: 'column',
    data: []
  },
   {
    name: 'Vendas por dia',
    type: isMobile ? 'line' : 'column',
    data: []
  }],
  options: {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
      }
    },
    colors: [],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 1, 4]
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        show: false,
        seriesName: 'Ganhos por dia',
        decimalsInFloat: 0,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#28A745'
        },
        labels: {
          style: {
            colors: '#28A745',
          }
        },
        title: {
          text: "Ganhos por dia",
          style: {
            color: '#28A745',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        show: false,
        seriesName: 'Vendas por dia',
        opposite: true,
        decimalsInFloat: 0,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: 'rgb(0, 123, 255)'
        },
        labels: {
          style: {
            colors: 'rgb(0, 123, 255)',
          },
        },
        title: {
          text: "Vendas por dia",
          style: {
            color: 'rgb(0, 123, 255)',
          }
        }
      },
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: (value, a) => {
          // Formatação de valores como moeda em R$
          let tipo = a.w.globals.initialSeries[a.seriesIndex].name;
          return tipo === "Ganhos" && value !== undefined ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : tipo === "Vendas"? value + " unid" : null ;
        }
      }
    },
    legend: {
      horizontalAlign: 'center',
      offsetX: 40
    }
  },
};


export default function GraficoCombinado({titulo ,tituloY1, tituloY2, cor1, cor2, dadosList, id }) {
  const [chartData, setChartData] = useState(initialChartData);

  useEffect(() => {
    if (dadosList && Array.isArray(dadosList.ganhos) && Array.isArray(dadosList.vendas)) {
      const newSeries = {
        ...initialChartData,
        series: [
          { ...initialChartData.series[0], data: dadosList.ganhos, name:tituloY1 },
          { ...initialChartData.series[1], data: dadosList.vendas, name:tituloY2 }
        ],

        options: { 
          ...initialChartData.options,
          colors:[cor1, cor2],
          xaxis:{categories: dadosList.datas},
          yaxis:[
            {...initialChartData.options.yaxis[0], seriesName:tituloY1, show:!isMobile,
              title:{...initialChartData.options.yaxis[0].title, text:tituloY1, style:{color:cor1}},
              axisBorder: {...initialChartData.options.yaxis[0].axisBorder, color:cor1},
              labels: {...initialChartData.options.yaxis[0].labels, style:{colors:cor1}}

            },
            {...initialChartData.options.yaxis[1], seriesName:tituloY2, show:!isMobile, 
              title:{...initialChartData.options.yaxis[1].title, text:tituloY2, style:{color:cor2}},
              axisBorder: {...initialChartData.options.yaxis[1].axisBorder, color:cor2},
              labels: {...initialChartData.options.yaxis[1].labels, style:{colors:cor2}}
            }
          ]

        }
        
      };
      setChartData(newSeries);
    }
  }, [dadosList]);

  useEffect(() => {
    if (chartData) {

    }
  }, [chartData]);

  return (
    <div style={{
      paddingTop: "5px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      backgroundColor: "#ffff",
      height: "520px",
      minWidth: isMobile ? window.innerWidth - 30 : "320px",
      width: "91.5%",
      borderRadius: "10px"
    }}
    id={id}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <h3 style={{ margin: "2px", textAlign: "center", color:"rgb(106, 106, 106)"}}>{titulo}</h3>
      </div>
      <Chart options={chartData.options} series={chartData.series} type="line" height={470} />
    </div>
  );
}