import { getDatabase, ref, child, get } from "firebase/database";
import { app } from "./configuracoes";

export function obterTodosRegistros (local){
    const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
    const dbRef = ref(getDatabase(app));
    return new Promise((resolve, reject)=>{
        get(child(dbRef, `DadosUsuarios/${dadosUsuario.uid +"/"+local}`)).then((snapshot)=>{
            if(snapshot.exists()){
                resolve(snapshot.val());
            }else{
                console.log("sem dados")
            }
        }).catch((error)=>{
            reject(error)
        })
    })
};