import { getDatabase, ref, set } from "firebase/database";
import { app } from "./configuracoes";

export default function firebaseSendDados (dados, status){
    const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
    return new Promise ((resolve, reject)=>{
        if(status === true){
            if(dadosUsuario){
                const db = getDatabase(app);
                set(ref(db, "DadosUsuarios/"+dadosUsuario.uid +"/Registros/"+ dados.codigo), dados)
                .then(dados=>resolve("sucesso"))
                .catch(error=>reject(error))
            }else{
                reject("usuario não logado");
             }
        }else{
            reject("sem conexao");
        };
    })
};