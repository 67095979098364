// Este código é gerado pelo Create React App para registrar o service worker

import Notificador from "./componentes/notificador";

// O ajuste é opcional se já existir um arquivo similar
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] é o endereço localhost IPv6.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 são considerados localhost IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/
    )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // O construtor do URL é disponível em todos os browsers que suportam SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Nosso service worker não funcionará se PUBLIC_URL estiver em um
      // origem diferente do que a nossa página.
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // Isso é executado em localhost. Vamos verificar se o service worker ainda existe ou não.
        checkValidServiceWorker(swUrl, config);

        // Adicione algum log adicional para localhost, indicando que o aplicativo está em execução
        // dentro do modo de desenvolvimento e o service worker / PWA só funcionará em produção.
        navigator.serviceWorker.ready.then(() => {
          // Notificador("Sucesso", "Conteúdo salvo para uso offline.","success");
        });
      } else {
        // Não é localhost. Basta registrar o service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // No momento, o conteúdo armazenado em cache foi atualizado.
              Notificador("Sucesso", "Seu aplicativo foi atualizado, por favor feche ele e abra novamente.","success");
              // Executar callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // No momento, todo o conteúdo pré-cacheado foi instalado.
              // Notificador("Sucesso", "Conteúdo salvo para uso offline.","success");
              // Executar callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      Notificador("Erro", "Erro durante o registro do service worker :" + error,"warning");
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Verifique se o service worker pode ser encontrado. Se não puder recarregar a página.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Certifique-se de que o service worker existe, e que realmente estamos recebendo um arquivo JS.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // Nenhum service worker encontrado. Provavelmente uma página diferente. Recarregar a página.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker encontrado. Prossiga normalmente.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      // Notificador("Atenção","Você está acessando offline.","warning");
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
