import { getDatabase, ref, set } from "firebase/database";
import { app } from "./configuracoes";

export default function telemetria (dado){
    const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
    const db = getDatabase(app);
   if(dadosUsuario){
        set(ref(db, "Telemetria/" + dadosUsuario.uid +"/Status"+Date.now()), dado)
        .then(dados=>{})
        .catch(error=>{})
   }
};