import { obterTodosRegistros } from "./firebase/obterDadosUmaVez";
import gravarDados from "./firebase/gravarDados";
import dataValor from "./obterData";

export default function refreshDadosSendFirebase (){
    const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
    const dadosLocalStorage = JSON.parse(localStorage.getItem("Cronometros"));

    if(dadosLocalStorage && dadosUsuario){
        obterTodosRegistros("Registros").then(dados=>{
            const dadosRespostaFirebase = Object.entries(dados);
            dadosLocalStorage.forEach((elementLocal, index) => {
                let respostaFiltroFirebase = dadosRespostaFirebase.filter(([ key, list])=> key == elementLocal.codigo);

                if(respostaFiltroFirebase.length>0){
                    if(respostaFiltroFirebase[0][1].versao !== elementLocal.versao){
                        //verifica se a versao firebase esta atualizada
                        gravarDados(`Registros/${elementLocal.codigo}`, elementLocal);
                    }else{
                        if((elementLocal.concluido || elementLocal.finalizado || elementLocal.cancelado) && !elementLocal.inicio.includes(dataValor()) ){
                            dadosLocalStorage.splice(index, 1);
                            localStorage.setItem("Cronometros", JSON.stringify(dadosLocalStorage));
                        }
                    }
                }else{
                    gravarDados(`Registros/${elementLocal.codigo}`, elementLocal);
                };
            });
        })
        .catch(error=>{
            console.log(error)
        })
    }
}