import { cash, save, relogio, moneyBag, remove, briquedo } from "../../componentes/imagens";
import { HeaderSuperior } from "../../componentes/painel_header";
import { useNavigate, useLocation} from "react-router-dom";
import '../temposValores/temposValores.css';
import { useEffect, useState } from "react";
import BotaoAdd from "../../componentes/BotaoAdd";
import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from "../../componentes/firebase/configuracoes";
import gravarDados from "../../componentes/firebase/gravarDados";
import FormNovoDado from "../../componentes/FormNovoDado";

export default function TemposValores(){

    const propsPagina = useLocation();
    const navigate = useNavigate();

    const [tituloPagina, setTituloPagina] = useState(propsPagina.state);
    const [visibilidadeForm, setVisibilidadeForm] = useState(false);
    const [valoresLista, setValoresLista] = useState([]);

    useEffect(()=>{
        window.addEventListener('beforeunload', function(event) {
            event.preventDefault();
        });
        
        const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
        const db = getDatabase(app);
        const starCountRef = ref(db, "DadosUsuarios/"+dadosUsuario.uid + "/" + tituloPagina.localFirebase);
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            setValoresLista(data?[...data]:[]);
        });

    },[]);

    function salvarNovoTempo (valores){
        if(valores){
            setValoresLista(prev=>{
                prev = [...prev];
                if(tituloPagina.titulo === "Cadastrar tempos"){
                    if(valores.caixa1){
                        prev.push({minutos:parseInt(valores.caixa1), data:Date.now()});
                        gravarDados(tituloPagina.localFirebase, prev);
                        setVisibilidadeForm(false);
                    }
                }else{
                    if(valores.caixa1 && valores.caixa2){
                        prev.push({brinquedo:valores.caixa1, valorPorMinuto:parseInt(valores.caixa2), data:Date.now()});
                        gravarDados(tituloPagina.localFirebase, prev);
                        setVisibilidadeForm(false);
                    }
                }
                return prev;
            });
        }
    };

    function visibilidadeDoFormulario (){
        setVisibilidadeForm(true);
    };

    function deletaritem (index){
        setValoresLista(prev=>{
            prev = [...prev];
            prev.splice(index, 1);
            gravarDados(tituloPagina.localFirebase, prev);
            return prev
        })
    };

    const temposLista = 
        valoresLista.map((valoresLista, index)=>{
            return(
                <ItemListaValores
                    key={index}
                    valoresLista={valoresLista.minutos}
                    deletarItem={()=>deletaritem(index)}
                />
            )
        });

        const brinquedosLista = 
        valoresLista.map((valoresLista, index)=>{
            return(
                <ItemListaValoresBrinquedo
                    key={index}
                    valoresLista={valoresLista}
                    deletarItem={()=>deletaritem(index)}
                />
            )
        });

    return(
        <div className="div-valoresLista-principal">
            <FormNovoDado
                visibilidade={visibilidadeForm}
                setVisibilidadeForm={setVisibilidadeForm}
                placeholder={{caixa1: tituloPagina.titulo ==="Cadastrar tempos"? "Tempo em Minutos":"Nome do brinquedo", caixa2:"Valor cobrado por minuto", visibilidadeCaixa:tituloPagina.titulo ==="Cadastrar tempos"? "none":"flex"}}
                tipoValor={{caixa1: tituloPagina.titulo ==="Cadastrar tempos"? "number":"text", caixa2:"number"}}
                iconeBtn={save}
                iconeInput={{caixa1:tituloPagina.titulo ==="Cadastrar tempos"? relogio:briquedo, caixa2:cash}}
                titulo={tituloPagina.titulo === "Cadastrar tempos"? "Novo tempo":"Novo brinquedo"}
                salvarClique={salvarNovoTempo}
            />
            <HeaderSuperior
                titulo={tituloPagina.titulo}
                eventClique={()=>navigate("/home")}
            />

            <div className="div-lista">
                <div className="lista">
                    {tituloPagina.titulo === "Cadastrar tempos"?temposLista:brinquedosLista}
                    <BotaoAdd
                        eventoDeClique={visibilidadeDoFormulario}
                    />
                </div>
            </div>
        </div>
    )
};

const estiloP={
    textAlign:"center",
     color:"#007E7A",
    fontWeight:"600",
    pointerEvents:"none",
    minWidth:"120px"
};

function ItemListaValores ({valoresLista, deletarItem}){
    return(
        <div className="div-item-lista">
            <div className="div-card">
                <img style={{width:"30px", height:"30px"}} src={relogio} alt="icone valoresLista"/>
                <div className="div-text">
                    <p style={{...estiloP, fontWeight:"700"}}>Minutos</p>
                    <p style={{...estiloP}}>{`${valoresLista}:00`}</p>
                </div>
            </div>
            <img onClick={deletarItem} style={{width:"45px", height:"45px", cursor:"pointer"}} src={remove} alt="botão deletar"/>
        </div>
    )
};


function ItemListaValoresBrinquedo ({valoresLista, deletarItem}){
    return(
        <div className="div-item-lista">
            <div className="div-card" style={{gap:"2px"}}>
                <img style={{width:"30px", height:"30px"}} src={briquedo} alt="icone valoresLista"/>
                <div className="div-text-brinquedo">
                    <p style={{...estiloP, fontWeight:"700"}}>Nome</p>
                    <p style={{...estiloP}}>{valoresLista.brinquedo}</p>
                </div>
            </div>

            <div className="div-card" style={{gap:"2px"}} >
                <img style={{width:"30px", height:"30px"}} src={moneyBag} alt="icone valoresLista"/>
                <div className="div-text-brinquedo" style={{flexDirection:"column"}}>
                    <p style={{...estiloP, fontWeight:"700",}}>Valor Min.</p>
                    <p style={{...estiloP}}>{`R$ ${valoresLista.valorPorMinuto},00`}</p>
                </div>
            </div>

            <img onClick={deletarItem} style={{width:"45px", height:"45px", cursor:"pointer"}} src={remove} alt="botão deletar"/>
        </div>
    )
};