import { removep } from "./imagens";

export default function Planos({planosLista, close}){

    return(
        <div style={{backgroundColor:"#000000b0", position:"absolute",zIndex:10, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
            <div style={{borderRadius:"10px",backgroundColor:"#ffff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", width:"90%", maxWidth:"400px", height:"90%"}}>
                <div style={{width:"90%",padding:"5px", display:"flex", flexDirection:"column", alignItems:"flex-end" }}>
                    <img onClick={close} style={{cursor:"pointer"}} src={removep} alt="close formulario"/>
                </div>
                <h1 style={{fontSize:"28px", color:"black", fontWeight:"600"}}>Escolha seu plano</h1>
                <div style={{overflowY:"auto",scrollbarWidth:"none",width:"100%",gap:"10px",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", padding:"10px"}}>
                    {
                        planosLista.map((item, index)=>{
                            return(
                                <CardPlano key={index} plano={item} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};


function CardPlano ({plano}){
    return(
        plano&&
        <div style={{borderRadius:"10px",display:"flex", flexDirection:"column", width:"88%",padding:"10px", border:"solid 1px #d3d3d3", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"center"}}>
                <h2 style={{fontSize:"25px", fontWeight:"600", color:"black", margin:"0"}}>{plano.Nome}</h2>
                <h3 style={{fontSize:"16px", fontWeight:"600", margin:"0"}} >{plano.SubTitulo}</h3>
            </div>
            <div style={{justifyContent:"flex-start",alignItems:"center", gap:"5px",marginTop:"20px", marginBottom:"20px",display:"flex", flexDirection:"row", width:"100%"}} >
                <h3 style={{margin:"0",color:"black", fontSize:"25px", fontWeight:"700"}}>{plano.Valor}</h3>
                <p style={{margin:"0",width:"100px"}}>{plano.recorencia}</p>
            </div>
            <div>
                {
                    plano.Itens.map((item)=>{
                        return(
                            <ItemLista key={item.item} item={item.item} add={item.disponivel}/>
                        )
                    })
                }
            </div>
            <div style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <button style={{width:"80%", borderRadius:"5px", marginTop:"10px", backgroundColor:"black"}}>{`Selecionar ${plano.Nome}`}</button>
            </div>
        </div>
    )
}

function IconMais ({add = false}){
    return(
        add?
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#007E7A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-check mr-2 h-4 w-4 text-green-500"
                data-id="32"
                >
                <path d="M20 6 9 17l-5-5"></path>
            </svg>
            : <img src={removep} alt="icone plano"/>
    )
};

function ItemLista({item, add}){
    return(
        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px"}}>
            <IconMais add={add}/>
            <h4 style={{margin:"0", fontWeight:"500", fontSize:"15px"}}>{item}</h4>
        </div>
    )
};