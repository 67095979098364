import React, { useState } from 'react';

function Calendario({data, close}) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();

  const handlePreviousMonth = () => {
    setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1));
  };

  const handleDateClick = (date) => {
    const dateSelec = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), date)
    data(dateSelec);
    close(false);
  };

  return (
    <div className="calendar-container">
      <div className="calendar">
        <div className="calendar-header">
          <button className="calendar-nav-button" onClick={handlePreviousMonth}>&#8249;</button>
          <h2 style={{color:"#ffff",width:"80%"}} className="calendar-title">{selectedDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })}</h2>
          <button className="calendar-nav-button" onClick={handleNextMonth}>&#8250;</button>
          <span onClick={()=>close(false)} style={{color:"red", fontSize:"22px", fontWeight:"700", width:"40px",cursor:"pointer"}}>X</span>
        </div>
        <div className="calendar-body">
          <div className="calendar-days">
            {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'].map((day) => (
              <div key={day} className="calendar-day-label">{day}</div>
            ))}
          </div>
          <div className="calendar-dates">
            {Array.from({ length: firstDayOfMonth }, (_, i) => <div key={`empty-${i}`} className="calendar-date"></div>)}
            {Array.from({ length: daysInMonth }, (_, i) => (
              <div
                key={i + 1}
                className={`calendar-date ${selectedDate.getDate() === i + 1 ? 'selected' : ''}`}
                onClick={() => handleDateClick(i + 1)}
              >
                {i + 1}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendario;