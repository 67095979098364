import { isMobile, isTablet } from "react-device-detect";
import ItemCronometroFinalizados from "./ItemCronometroFinalizado";
import {useState } from "react";
import dataValor from "./obterData";


export default function PainelFinalizados(){
    const [listaCronometrosFinalizados, setListaCronometrosFinalizados] = useState(buscarCronometros());

    function buscarCronometros (){
        let lista = JSON.parse(localStorage.getItem("Cronometros"));
        if(lista){
            lista = lista.length>0?lista.filter((lista)=> lista.inicio.includes(dataValor()) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado):[];
            return lista
        }else{
            return [];
        }
    };

    const cronometros = listaCronometrosFinalizados.reverse().map(( item ,index)=>{
        if(item.finalizado === true){
            return(
                <ItemCronometroFinalizados
                    key={index}
                    objetoCronometro={item}
                />
            )
        }
    });

    return(
        <>
            <div style={{display:"flex", flexWrap:"wrap", overflowY:"auto", maxHeight:"85vh",minWidth:"358px", justifyContent:"flex-start", marginTop:"60px", marginLeft:(isMobile && !isTablet)?(window.innerWidth - 374)/2<=0?"0px":(window.innerWidth - 374)/2:"10px"}}>
                {cronometros}
            </div>
        </>
    )
};