import {useState } from 'react';
import {HeaderSuperior, HeaderInferior} from '../../componentes/painel_header';
import '../cronometro/cronometro.css';
import { useNavigate } from 'react-router-dom';
import PainelFinalizados from '../../componentes/PainelFinalizados';
import PainelDeCronometros from '../../componentes/PainelCronometros';
import Ganhos from '../../componentes/Ganhos';

export default function Cronometro (){

    const navigate = useNavigate();

    const [paginaSelecionada, setPaginaSelecionada] = useState("Cronômetros");

    return(
        <div className="painel-principal-cronometro" >
            <HeaderSuperior
                eventClique={()=>navigate("/home")}
                titulo={paginaSelecionada}
            />
            <div style={{height:window.innerHeight-120, width:"100%",paddingBottom:"65px", display:"flex", flexDirection:"column"}}>
                {
                    paginaSelecionada === "Cronômetros"? <PainelDeCronometros/>
                        : paginaSelecionada === "Finalizados"?<PainelFinalizados/>
                            :<Ganhos/>
                
                
                }
            </div>
            <HeaderInferior
                eventoDeClique={setPaginaSelecionada}
            />
        </div>
    )
};