
import { icone_crianca, relogio, moneyBag, responsavel, briquedo } from "./imagens";

export default function ItemCronometroFinalizados({objetoCronometro}) {
  
  const estiloH3 = {width:"100%", pointerEvents: "none", color: "#007E7A", fontSize: "18px", fontWeight: "700", margin:"0", padding:"0", height:"28px", textAlign:"start"};

  return (
    <div style={{padding:"4px"}}>
      <div style={{ boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: "pointer", gap: "5px", display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", width: "330px",padding:"10px", backgroundColor: "#ffff", borderRadius: "10px", overflow:"hidden" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={icone_crianca} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Nome: ${objetoCronometro.crianca}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={responsavel} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Responsável: ${objetoCronometro.responsavel}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={briquedo} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Brinquedo: ${objetoCronometro.brinquedo}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={relogio} alt="Ícone Criança" />
          <h3 style={estiloH3}>{objetoCronometro.tipoCronometro ==="Livre"?`Tempo: ${objetoCronometro.tempoLivreTotal} `:`Tempo: ${objetoCronometro.tempo} `}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={relogio} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Tipo de cronômetro: ${objetoCronometro.tipoCronometro === "Livre"?"Livre":"Selecionado"}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={relogio} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Inicio: ${objetoCronometro.inicio}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={relogio} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Fim: ${objetoCronometro.previsaoFim}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={relogio} alt="Ícone Criança" />
          <h3 style={estiloH3}>{`Status: ${objetoCronometro.cancelado?"Cancelado":"Concluido"}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={moneyBag} alt="QR Code" />
          <h3 style={estiloH3}>{`Pagamento: ${objetoCronometro.valorPago === 0 ?"Cancelado":"Concluido"}`}</h3>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "10px", height: "30px" }}>
          <img style={{ width: "30px", height: "30px" }} src={moneyBag} alt="QR Code" />
          <h3 style={estiloH3}>{objetoCronometro.tipoCronometro === "Livre"?`Valor pago: $ ${objetoCronometro.valorPago.replace(".",",")}`:`Valor pago: $ ${objetoCronometro.valorPago},00`}</h3>
        </div>
      </div>
    </div>
  );
}
