import 'react-date-range/dist/styles.css';  // arquivo de estilo principal 
import 'react-date-range/dist/theme/default.css';  // arquivo css do tema
import { DateRangePicker } from 'react-date-range';
import { ptBR } from 'date-fns/locale';
import { useState} from 'react';
import './calendario.css'; // Arquivo CSS adicional para customizações

export default function Calendario({ocultar, setDataInterva}) {
    const [datas, setDatas] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    function handleSelect(ranges) {
        const { selection } = ranges;
        setDatas(selection);
        setDataInterva(selection);
    }

    const customLocale = {
        ...ptBR,
        formatDistance: (token, count, options) => {
          const result = ptBR.formatDistance(token, count, options);
          return result.charAt(0); // Retorna apenas a inicial
        },
        localize: {
          ...ptBR.localize,
          day: (n) => 'DSTQQSS'.charAt(n),
        },
      };
    return (
        <div style={{position:"absolute",zIndex:1000,width:"100%", height:"100%", backgroundColor:"rgba(0, 0, 0, 0.612)", alignItems:"center", justifyContent:"center", display:"flex"}}>
            <div className="calendar-wrapper">
                <h1 style={{margin:"0", maxWidth:"250px" ,textAlign:"center", color:"#ffff", fontWeight:"500"}}>Selecione o intervalor que deseja visualizar</h1>
                <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                    <span onClick={ocultar}  style={{color:"red", fontSize:"20px", fontWeight:"700", width:"35px",cursor:"pointer"}}>X</span>
                </div>
                <DateRangePicker
                    ranges={[datas]}
                    onChange={handleSelect}
                    locale={customLocale}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    direction="horizontal"
                    showDateDisplay={false}
                    staticRanges={[]}  // Remove as predefinições
                    inputRanges={[]}  // Remove as entradas de range personalizadas
                />
            </div>

        </div>
    );
}
