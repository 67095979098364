import '../login/login.css';
import { icone, email,cadeado } from '../../componentes/imagens';
import { useEffect, useState } from 'react';
import BtnVoltar from '../../componentes/BtnVoltar';
import Notificador from '../../componentes/notificador';
import { isMobile} from 'react-device-detect';
import cadastreSe from '../../componentes/firebase/cadastreSe';
import NotificacaoPersonalizada from '../../componentes/notificacaoPersonalizado';
import ActiveIndicador from '../../componentes/Activeindicador';
import resetSenhaEmail from '../../componentes/firebase/reset';
import loginConta from '../../componentes/firebase/login';
import { useNavigate } from 'react-router-dom';
import LoginMetodo from '../../componentes/LoginMetodos';

const usuario = JSON.parse(localStorage.getItem("usuarioLogado"));

export default function Login(){

    const [animacaoImg, setAnimacaoImage] = useState(true);
    const [formsExibir, setFormsExibir] = useState("Brinquedômetro");

    const [emailInp, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaRepit, setSenhaRepit] = useState('');
    const [visibilidadeNotificacao, setVisibilidadeNotiticacao] = useState(null);
    const [activeIndicador, setActiveIndicador] = useState(false);
    
    function validacaoFormCadastre(){
        if(emailInp === ''){
            Notificador("Digite seu e-mail!","","warning");
        }else if(senha === ''){
            Notificador("Digite sua senha!","","warning");
        }else if(senhaRepit === ''){
            Notificador("Repita sua senha!","","warning");
        }else if(senha !== senhaRepit){
            Notificador("As senhas digitadas são diferentes!","","warning");
        }else{
            setActiveIndicador(true);
            cadastreSe(emailInp, senha).then(dados=>{
                setVisibilidadeNotiticacao(dados);
                setActiveIndicador(false);
            }).catch(error=>{
                error();
                setActiveIndicador(false);
            });
        }
    };

    function removerAnimacao(){
        const timeInterval = setTimeout(()=>{
            setAnimacaoImage(false);
        }, 2000);
        return ()=> clearTimeout(timeInterval);
    };

    useEffect(()=>{        
        const limparTemporizador = removerAnimacao();
        return limparTemporizador;
    },[]);

    return(
        <div className='div-login-principal div-flex-column'>
            <ActiveIndicador visibilidade={activeIndicador}/>
            <NotificacaoPersonalizada
                visibilidade={visibilidadeNotificacao !== null}
                clique={()=>{
                    setVisibilidadeNotiticacao(null);
                    setFormsExibir("Brinquedômetro");
                }}
                icone={email}
                cor={"#007E7A"}
                texto={visibilidadeNotificacao}
            />
            <BtnVoltar clique={()=>setFormsExibir("Brinquedômetro")} visibilidade={formsExibir !== "Brinquedômetro"}/>
            <IconeNome text={formsExibir} animacao={animacaoImg}/>
            <FormLogin activeIndicador={setActiveIndicador} setVisibilidadeNotiticacao={setVisibilidadeNotiticacao} clique={setFormsExibir} visibilidade={animacaoImg === false && formsExibir === "Brinquedômetro"}/>
            <FormCadastre 
                visibilidade={formsExibir === "Cadastre-se"}
                emailUser={setEmail}
                senha={setSenha}
                senhaRepet={setSenhaRepit}
                cliqueCadastreSe={validacaoFormCadastre}
            />
            <FormResetSenha activeIndicador={setActiveIndicador} setNotificacao={setVisibilidadeNotiticacao} visibilidade={formsExibir ==="Recuperação de conta"}/>
        </div>
    )
};

function FormLogin({visibilidade, clique, activeIndicador, setVisibilidadeNotiticacao}){
    const navigate = useNavigate();

    const [emailInput, setEmailInput] = useState(usuario?usuario.email:"");
    const [senhaInput, setSenhaInput] = useState("");

    async function login(){
        if(emailInput ===""){
            Notificador("Digite seu e-mail!", "","warning");
        }else if(senhaInput ===""){
            Notificador("Digite sua senha!", "","warning");
        }else{
            try{
                await loginConta(emailInput, senhaInput, activeIndicador, navigate, setVisibilidadeNotiticacao);
                navigate("/home");
            }catch(error){
                Notificador("Sem acesso a internet", "Fique tranquilo armazenarei tudo offline para enviar depois.", "warning");
                navigate("/home");
            };
        }
    };


    return(
        visibilidade&&
        <div style={isMobile?{width:"97%"}:{width: "50vmin"}} className='div-login-form div-flex-column'>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={email}/>
                <input value={emailInput} onChange={(event)=>setEmailInput(event.target.value)} placeholder='Digite seu e-mail' type="email"/>
            </div>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={cadeado}/>
                <input onChange={(event)=>setSenhaInput(event.target.value)} placeholder='Digite sua senha' type="password"/>
            </div>
            <div className='div-flex-column'><a onClick={()=>clique("Recuperação de conta")}>Esqueceu a senha?</a></div>
            <div className='div-flex-column div-btn-login'>
                <button  onClick={login}>Entrar</button>
            </div>
            <div className='div-flex-row'><span style={{fontWeight:"500", fontSize:"15px", color:"#A5A5AA"}}>Ainda não possui conta?</span><a onClick={()=>clique("Cadastre-se")} style={{fontWeight:"600", fontSize:"15px", width:"120px"}}>Cadastre-se</a></div>
            <LoginMetodo callback={navigate}/>
        </div>
    )
};

function FormCadastre({visibilidade, emailUser, senha, senhaRepet, cliqueCadastreSe}){
    const navigate = useNavigate();
    return(
        visibilidade&&
        <div style={isMobile?{width:"97%"}:{width: "50vmin"}} className='div-login-form div-flex-column'>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={email}/>
                <input onChange={(event)=>emailUser(event.target.value)}  required placeholder='Digite seu e-mail' type="email"/>
            </div>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={cadeado}/>
                <input onChange={(event)=>senha(event.target.value)} required placeholder='Digite sua senha' type="password"/>
            </div>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={cadeado}/>
                <input onChange={(event)=>senhaRepet(event.target.value)} required placeholder='Digite sua senha novamente' type="password"/>
            </div>
            <div className='div-flex-column div-btn-login'>
                <button onClick={cliqueCadastreSe}>Cadastrar</button>
            </div>
            <LoginMetodo callback={navigate} titulo={"Ou cadastre-se usando"}/>
        </div>
    )
};

function FormResetSenha({visibilidade, setNotificacao, activeIndicador}){
    const [emailInput, setEmail] = useState('');

    function reset(){
        if(emailInput !==""){
            resetSenhaEmail(emailInput, setNotificacao, activeIndicador);
        }else{
            Notificador("Digite seu e-mail!", "", "warning");
        }
    };

    return(
        visibilidade&&
        <div style={isMobile?{width:"97%", height:"50%", gap:"15px", margin:"34px"}:{width: "50vmin",height:"50%", gap:"15px", margin:"34px"}} className='div-login-form div-flex-column'>
            <div className='div-flex-row'><span style={{fontWeight:"500", fontSize:"15px", color:"#A5A5AA"}}>Para recuperar sua conta digite o e-mail que foi cadastrado.</span></div>
            <div className='div-flex-row div-input-login'>
                <img style={{width:"34px", height:"34px"}} src={email}/>
                <input onChange={(event)=>setEmail(event.target.value)} placeholder='Digite seu e-mail' type="email"/>
            </div>
            
            <div className='div-flex-column div-btn-login'>
                <button onClick={reset}>Recuperar</button>
            </div>
        </div>
    )
};

function IconeNome({animacao, text}){
    return(
        <div className='div-icone-nome div-flex-column'>
            <img className={animacao?'icone':{with:"164px", height:"164px"}} style={{width:"164px", height:"162px"}} src={icone} alt='icone app'/>
            <h1 style={{color:"#ffff", fontSize:"32px", width:"90%", textAlign:"center", margin:"0"}}>{text?text:"Brinquedômetro"}</h1>
        </div>
    )
};