function intervalDatas (intervalo){
    const dataInicial = new Date(intervalo.startDate);
    const dataFim = new Date(intervalo.endDate);
    const datas = [];

    let currentDate = new Date(dataInicial);

    while (currentDate <= dataFim){
        datas.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return datas
};

const listaBrinquedos = [];
function listaDeBrinquedos (lista = []){
    lista.forEach((item)=>{
        if(!listaBrinquedos.includes(item.brinquedo.trim())){
            listaBrinquedos.push(item.brinquedo.trim());
        };
    });
};

function stringParaDate(dataString) {
    const [dia, mes, ano] = dataString.split('/').map(Number);
    return new Date(ano, mes - 1, dia); // Subtraímos 1 do mês porque o índice começa em 0
};

const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
function obterNomeDoDia(data) {
    const indiceDia = stringParaDate(data).getDay();
    return diasDaSemana[indiceDia];
};

function dataFormat (dataValor){

    function formatData (dataV){
        let result = dataV;
        if(dataV<10){
            result = `0${dataV}`;
        }
        return result;
    }
    return `${formatData(dataValor.getDate())}/${formatData(dataValor.getMonth()+1)}/${dataValor.getFullYear()}`;
}

export function GanhosXVendaPorDia (dados, intervalo, inicializacao){
    listaDeBrinquedos(dados);
    let intervaloDeVerificacao = [];

    if(inicializacao === true){
        dados.filter(lista => (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado).forEach((item)=>{
            const data = item.inicio.split(" ");
            if(data.length === 2){
                if(!intervaloDeVerificacao.includes(data[0])){
                    intervaloDeVerificacao.push(data[0]);
                }
            }
        })
    }else{
        intervaloDeVerificacao = intervalDatas(intervalo);
    };

    let listasDeDadosDoIntervalo = [];

    let datasComDados = {dias:[], meses:[], anos:[]};

    let cardsDados = {mediaVendasDia:0,mediaGanhosDia:0, totalGanhos:0, totalVendas:0};
    let ganhosXdia = {datas:[], ganhos:[], vendas:[]};
    let ganhosXmes = {datas:[], ganhos:[], vendas:[]};
    let ganhosXano = {datas:[], ganhos:[], vendas:[]};

    let brinquedosRanking = [];
    let dadosDiasRanking = {nomes:diasDaSemana, valores:[]}

    let diasRanking = {
        Domingo:0,
        Segunda:0,
        Terça:0,
        Quarta:0,
        Quinta:0,
        Sexta:0,
        Sabado:0
    }

    if(intervaloDeVerificacao.length>0){
        intervaloDeVerificacao.sort((a, b) => new Date(inicializacao? stringParaDate(a) : stringParaDate(dataFormat(a))) - new Date(inicializacao? stringParaDate(b) : stringParaDate(dataFormat(b)))); //ordenação da data
        intervaloDeVerificacao.forEach((data)=>{
            const dataDia = inicializacao? data : dataFormat(data);
            const mes = dataDia.split("/")[1]
            const ano = dataDia.split("/")[2]
            let valor = dados.filter((lista)=> lista.inicio.includes(dataDia) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado); 
            if(valor.length>0){
                listasDeDadosDoIntervalo = [...listasDeDadosDoIntervalo, ...valor];
                datasComDados.dias.push(dataDia);
                if(!datasComDados.meses.includes(`${mes}/${ano}`)){
                    datasComDados.meses.push(`${mes}/${ano}`);
                }

                if(!datasComDados.anos.includes(ano)){
                    datasComDados.anos.push(ano);
                }
            }
        });
        datasComDados.dias.forEach((item)=>{
            const registrosPorDia = listasDeDadosDoIntervalo.filter((lista)=>lista.inicio.includes(item));
            let ganhosNoDia = 0;

            brinquedosRanking.push(brinquedoMaisUtilizado(registrosPorDia));
            
            registrosPorDia.forEach((item)=>{
                ganhosNoDia = ganhosNoDia + Number(item.valorPago);
                diasRanking[obterNomeDoDia(item.inicio.split(" ")[0])] = diasRanking[obterNomeDoDia(item.inicio.split(" ")[0])] + Number(item.valorPago);
            });

            

            ganhosXdia.datas.push(item); //datas do dia
            ganhosXdia.vendas.push(registrosPorDia.length.toFixed(0)) //total vendas dia
            ganhosXdia.ganhos.push(Number(ganhosNoDia.toFixed(2))); //total ganhos dia
        })

        datasComDados.meses.forEach((item)=>{
            
            const registrosPorMes = ganhosXdia.datas.filter((lista)=>lista.includes(item));
            let ganhosNoMes = 0;
            let vendasNoMes = 0;
            registrosPorMes.forEach((item)=>{
                ganhosNoMes = ganhosNoMes + Number(ganhosXdia.ganhos[ganhosXdia.datas.indexOf(item)]);
                vendasNoMes = vendasNoMes + Number(ganhosXdia.vendas[ganhosXdia.datas.indexOf(item)]);
            });

            ganhosXmes.ganhos.push(ganhosNoMes.toFixed(2));
            ganhosXmes.vendas.push(vendasNoMes.toFixed(0));
            ganhosXmes.datas.push(item);
        })

        datasComDados.anos.forEach((item)=>{
            const registrosPorAno = ganhosXmes.datas.filter((lista)=>lista.includes(item));
            let ganhosNoAno = 0;
            let vendasNoAno = 0;
            registrosPorAno.forEach((item, index)=>{
                ganhosNoAno = ganhosNoAno + Number(ganhosXmes.ganhos[index]);
                vendasNoAno = vendasNoAno + Number(ganhosXmes.vendas[index]);
            });
            ganhosXano.ganhos.push(ganhosNoAno.toFixed(2));
            ganhosXano.vendas.push(vendasNoAno.toFixed(0));
            ganhosXano.datas.push(item);
        })

        cardsDados.totalGanhos = Number(somarValores(ganhosXdia.ganhos).toFixed(2));
        cardsDados.totalVendas = Number(somarValores(ganhosXdia.vendas).toFixed(2));
        cardsDados.mediaGanhosDia = cardsDados.totalGanhos > 0 ? Number((somarValores(ganhosXdia.ganhos) / ganhosXdia.datas.length).toFixed(2)) : 0;
        cardsDados.mediaVendasDia = cardsDados.totalVendas > 0 ? Number((cardsDados.totalVendas / ganhosXdia.datas.length).toFixed(0)) : 0;

        const graficosPizzabrinq = concatBrinquedosRanking(brinquedosRanking);
        diasDaSemana.forEach(dia=>dadosDiasRanking.valores.push(Number(diasRanking[dia].toFixed(2))));
        // console.log(ganhosXano)
        let resultado = {ganhosXDia:ganhosXdia,ganhosXMes:ganhosXmes, ganhosXAno:ganhosXano, cards:cardsDados, brinquedos:graficosPizzabrinq, diasDaSemana:dadosDiasRanking};

        return resultado;
    }
};

function somarValores (lista){
    return lista.reduce((acumulador, valorAtual) => {
        return acumulador + Number(valorAtual)
    }, 0);
};

function brinquedoMaisUtilizado(dados) {
    let brinquedos = listaBrinquedos;
    let dadosBrinquedos = {};

    if (brinquedos.length>0 && dados) {
        brinquedos.forEach((brinquedoItem) => {
            // Inicializa a contagem para o brinquedo se ainda não foi inicializada
            if (!dadosBrinquedos[brinquedoItem]) {
                dadosBrinquedos[brinquedoItem] = { valor: 0 };
            }

            // Conta quantas vezes o brinquedo foi utilizado
            dados.forEach((item) => {
                if (item.brinquedo.trim() === brinquedoItem) {
                    dadosBrinquedos[brinquedoItem].valor += 1;
                }
            });
        });
    }

    return dadosBrinquedos;
};


function concatBrinquedosRanking (lista){
    let brinquedos = listaBrinquedos;

    if(brinquedos.length>0){
        let dadosBrinquedos = {};

        let dadosTratados =  {nomes:[], valores:[]};

        brinquedos.forEach((item)=>{
            if(!dadosBrinquedos[item]){
                dadosBrinquedos[item] = { valor: 0 };
                dadosTratados.nomes.push(item);
            };

            lista.forEach((totalDia) => {
            dadosBrinquedos[item].valor =  dadosBrinquedos[item].valor + totalDia[item].valor;
            });

        })

        dadosTratados.nomes.forEach(nome=>{
            dadosTratados.valores.push(Number(dadosBrinquedos[nome].valor.toFixed(2)));
        })

        return dadosTratados
    }
};