import React, { useState, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useSearchParams } from "react-router-dom";
import { relogio, responsavel, briquedo, cash, icone_crianca, moneyBag, help } from "../../componentes/imagens";
import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from '../../componentes/firebase/configuracoes';
import ActiveIndicador from '../../componentes/Activeindicador';
import BtnRefresh from '../../componentes/BtnRefresh';

function Time() {

    const meio = "prod";

    const url = meio === "development"?"ws://localhost:8080":"wss://app.brinquedometro.com.br/ws/";
    const [socketUrl] = useState(url);

    const [tempo, setTempo] = useState('00:00');
    const [searchParams, setSearchparams] = useSearchParams();
    const [cronometro, setCronometro] = useState();
    const [valorPagar, setValorPagar] = useState(0);
    const [visibilidadeConcl, setVisibilidadeConcl] = useState({visibilidade:false, msg:""});
    const [minutos, setMinutos] = useState(0);
    const [activeIndicador, setActiveIndicador] = useState(true);
    const [animarBtn, setAnimarBtn] = useState(false);

    function ativarAnimacao (){
        setAnimarBtn(true);
        const animarFun = setTimeout(()=>{
            setAnimarBtn(false);
            clearTimeout(animarFun);
        }, 2000)
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        setMinutos(totalSeconds);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const { sendMessage } = useWebSocket(socketUrl, {
        onMessage: (event) => {
            const dados = JSON.parse(event.data);
            if (dados.time !== undefined) {
                setTempo(formatTime(dados.time));
            }
        },
        shouldReconnect: (closeEvent) => true, // Tentar reconectar em caso de falha
    });

    useEffect(()=>{
        const idCronometro = searchParams.get("id");
        const v = searchParams.get("v");
        const db = getDatabase(app);
        const cronometro = ref(db, "DadosUsuarios/" + v + "/Registros/" + idCronometro);
        onValue(cronometro, (snapshot) => {
            let cronometroList = snapshot.val();
            setCronometro({...cronometroList});
        });
    },[]);

    useEffect(()=>{
        const idCronometro = searchParams.get("id");
        handleGetStatus(idCronometro);
        if(cronometro){
            if(cronometro.cancelado){
                setVisibilidadeConcl({visibilidade:cronometro.cancelado, msg:"Seu tempo foi cancelado. Por favor, procure o vendedor."});
            }else if(cronometro.finalizado){
                setVisibilidadeConcl({visibilidade:cronometro.finalizado, msg:"Seu tempo foi concluído. Por favor, procure o vendedor."});
            }else if(cronometro.pausado){
                setVisibilidadeConcl({visibilidade:cronometro.pausado, msg:"Seu tempo foi pausado. Por favor, procure o vendedor."});
            }else if(cronometro.concluido){
                setVisibilidadeConcl({visibilidade:cronometro.concluido, msg:"Seu tempo foi concluído. Por favor, procure o vendedor."});
            }else{
                setVisibilidadeConcl({visibilidade:false, msg:"Seu tempo foi concluído. Por favor, procure o vendedor."});
            }
        }
        setActiveIndicador(false);
    },[cronometro]);

    useEffect(()=>{
        if(cronometro !== undefined){
            setValorPagar((cronometro.valorPorMin/60) * minutos);
        }
    },[minutos]);

    const handleGetStatus = (clientId) => {
        ativarAnimacao();
        const data = {
            command: 'status',
            id: clientId,
        };
        sendMessage(JSON.stringify(data));
    };

    return (

        cronometro?<Cards animarBtn={animarBtn} clique={()=>handleGetStatus(cronometro.codigo)} cronometro={cronometro} visibilidadeConcl={visibilidadeConcl} tempo={tempo} valorPagar={valorPagar}/>:<ActiveIndicador  visibilidade={activeIndicador}/>
        
    );
}

export default Time;


function Cards ({cronometro, visibilidadeConcl, tempo, valorPagar, clique, animarBtn}){
    const stylosIcone ={width: "30px", height: "30px" };
    const styloHNome = { fontSize: "17px", color: "#007e7a", margin:"0", fontWeight:"700" };
    const styloHNomePropriedade = { fontSize: "16px", color: "#007e7a", margin:"0", fontWeight:"500" };

    return(
        <div style={{pointerEvents:"none",display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
            
            <div style={{pointerEvents:"none",display:visibilidadeConcl.visibilidade?"flex":"none", flexDirection:"column", alignItems:"center", justifyContent:"center", backgroundColor:"rgba(0, 0, 0, 0.70)", width:"100%", height:"100%", position:"absolute"}}>
                <h1 style={{...styloHNome, width:"80%", textAlign:"center", fontSize:"25px", color:"#ffff"}}>{visibilidadeConcl.msg}</h1>
            </div>

            <div style={{pointerEvents:"none", gap: "10px", width: "95%", maxWidth: "390px", height: "90%", display: "flex", flexDirection: "column"}}>
                <div style={{ gap: "15px", width: "100%", height: "200px", backgroundColor: "#ffff", borderRadius: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ gap:"30px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <img style={{ width: "80px", height: "80px" }} src={relogio} alt="icone relogio" />
                        <h1 style={{ fontSize: "70px", color: "#007e7a", margin:"0" }}>{tempo}</h1>
                    </div>

                    <div style={{ width:"90%",gap:"5px", display: cronometro.tipoCronometro ==="decrescente"?"none":"flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                        <img style={{ width: "20px", height: "30px" }} src={cash} alt="icone relogio" />
                        <h1 style={{ fontSize: "18px", color: "#007e7a", margin:"0" }}>{cronometro.finalizado?`R$ ${cronometro.valorPago.toString().replace(".",",")}`:`R$ ${valorPagar.toFixed(2).toString().replace(".",",")}`}</h1>
                    </div>

                </div>

                <div style={{width: "100%", backgroundColor: "#ffff", borderRadius: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={icone_crianca} alt="icone relogio" />
                        <h1 style={styloHNome}>Cliente:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.crianca}</h1>
                    </div>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={responsavel} alt="icone relogio" />
                        <h1 style={styloHNome}>Responsável:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.responsavel}</h1>
                    </div>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={briquedo} alt="icone relogio" />
                        <h1 style={styloHNome}>Brinquedo:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.brinquedo}</h1>
                    </div>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={relogio} alt="icone relogio" />
                        <h1 style={styloHNome}>Iniciado:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.inicio}</h1>
                    </div>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={relogio} alt="icone relogio" />
                        <h1 style={styloHNome}>Fim previsto:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.tipoCronometro ==="decrescente"?cronometro.previsaoFim:"Sem previsão"}</h1>
                    </div>

                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={cash} alt="icone relogio" />
                        <h1 style={styloHNome}>Valor total:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.tipoCronometro ==="decrescente"?`R$ ${cronometro.valorPago},00`:(cronometro.finalizado?`R$ ${cronometro.valorPago.toString().replace(".",",")}`:"Em andamento")}</h1>
                    </div>
                    <div style={{gap:"10px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"90%", padding:"2px"}}>
                        <img style={stylosIcone} src={moneyBag} alt="icone relogio" />
                        <h1 style={styloHNome}>Status do pagamento:</h1>
                        <h1 style={styloHNomePropriedade}>{cronometro.pagamento}</h1>
                    </div>

                </div>
                <div style={{gap:"10px", display:cronometro.tipoCronometro ==="decrescente"?"none":"flex", flexDirection:"row", alignItems:"center", justifyContent:"start", width:"95%", padding:"2px"}}>
                    <img style={stylosIcone} src={help} alt="icone relogio" />
                    <h1 style={{...styloHNome, color:"#ffff", fontWeight:"400", fontSize:"12px", textAlign:"justify"}}>Atenção: Devido à velocidade ou latência da sua internet, os valores exibidos aqui podem variar em alguns segundos e centavos. Portanto, sempre considere os valores mostrados no dispositivo do vendedor como referência para pagamento.</h1>
                </div>
                <BtnRefresh animar={animarBtn} clique={clique}/>
            </div>

        </div>
    )
}