import CardGanhos from "./CardGanhos";
import Tabela from "./Tabela";
import { HeaderSuperior } from "./painel_header";
import { useNavigate } from "react-router-dom";
import Calendario from "./Calendario";
import { useEffect, useState } from "react";
import './estiloAnimarCronometro.css'; // Arquivo CSS para estilização
import dataValor from "./obterData";

export default function Ganhos ({header}){
    const navigate = useNavigate();
    const [calendarioVisibilidade, setCalendarioVisibilidade] = useState(false);
    const [data, setData] = useState();
    const [lista, setLista] = useState([]);

    function formatData (dataV){
        let result = dataV;
        if(dataV < 10){
            result = `0${dataV}`;
        }
        return result;
    }

    function returnData (dataSelec){
        const data = new Date(dataSelec);
        setData(`${formatData(data.getDate())}/${formatData(data.getMonth()+1)}/${data.getFullYear()}`);
    };

    useEffect(()=>{
        const localRegistros = localStorage.getItem("Cronometros");
        setData(dataValor());

        if(localRegistros !== null && !header){
            let registros = JSON.parse(localRegistros);
            if(registros){
                setLista(registros);
            }
        }
    }, []);

    return (
        <>
        {header && <HeaderSuperior titulo={"Histórico de Ganhos"} eventClique={()=>navigate("/home")}/>}
        <div style={{gap:"20px",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"flex-start", width:"100%", height:"100%", marginTop:header?"0":"70px", paddingTop:header?"70px":"0"}}>
            {calendarioVisibilidade && <Calendario data={returnData} close ={setCalendarioVisibilidade}/>}
            <CardGanhos dataSelecionada ={data} calendario={setCalendarioVisibilidade} header={header} totalList={lista}/>
            <Tabela dataSelecionada ={data} valoresList={lista} header={header}/>
        </div>
        </>

    )
}