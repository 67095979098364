import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications} from 'react-notifications-component';
import { BrowserRouter } from 'react-router-dom';
import Rotas from './componentes/rotas';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        <ReactNotifications/>
        <BrowserRouter>
            <Rotas/>
        </BrowserRouter>
    </>
);


serviceWorkerRegistration.register();