import { getAuth, deleteUser } from "firebase/auth";
import { app } from "./configuracoes";
import Notificador from "../notificador";
import handleFirebaseError from "./erros";
import { getDatabase, ref, set } from "firebase/database";

export default function excluirUsuario(FuncaoCallback){
    const auth = getAuth(app);
    const user = auth.currentUser;

    excluirDados("Telemetria/" + user.uid +"/Status", {msg:"Usuario deletou a conta", data:Date.now()});
    excluirDados("DadosUsuarios/" + user.uid +"/", null);

    deleteUser(user).then(() => {
        localStorage.clear();
        FuncaoCallback("/");
        
    }).catch((error) => {
        Notificador(handleFirebaseError(error),"", "warning");
    });
};


function excluirDados (local, dado){
    const db = getDatabase(app);
    set(ref(db, local), dado)
      .then(dados=>{})
      .catch(error=>{})
};