import ItemCronometro from "./ItemCronometro";
import BotaoAdd from "./BotaoAdd";
import { useEffect, useState } from "react";
import VazioCronometros from "./SemCronometroNottf";
import FormNovoCronometro from "./FormularioNovoCronometro";
import Notificador from "./notificador";
import FormDetalhesCronometro from "./FormularioDetalhesCronometro";
import { isMobile, isTablet } from 'react-device-detect';
import NotificadorDeSelecao from "./NotificadorDeSelecao";
import { getDatabase, ref, onValue } from "firebase/database";
import firebaseSendDados from "./firebase/sendDadosCronometro";
import useWebSocket from 'react-use-websocket';

const listaTemposPadrao = [
    {minutos:5, data:Date.now()},
    {minutos:10, data:Date.now()},
    {minutos:15, data:Date.now()},
    {minutos:20, data:Date.now()}
];

const listaTempoPrsonalizada = [
    {minutos:"Personalizado", data:Date.now()},
    {minutos:"Livre", data:Date.now()}
];

export default function PainelDeCronometros(){

    const [statusConexaoFirebase, setStatusConexaoFirebase] = useState(false);
    const [listaDeCronometros, setListaDeCronometros] = useState([]);
    const [visibilidadeForm, setVisibilidadeForm] = useState(false);
    const [temposEValores, setTemposEValores] = useState([])
    const [brinquedosLista, setBrinquedosLista] = useState([]);
    const [nomeCrianca, setNomeCrianca] = useState("");
    const [nomeResponsavel, setNomeResponsavel] = useState("");
    const [brinquedo, setBrinquedo] = useState("");
    const [tempo, setTempo] = useState(0);
    const [statusDoPagamento, setStatusDoPagamento] = useState("");
    const [visibilidadeFormDetalhe, setVisibilidadeFormDetalhes] = useState(false);
    const [detalhesForm, setDetalhesForm] = useState();
    const [visibilidadeNotificacaoSelecao, setVisibilidadeNotificacaoSelecao] = useState({visibilidade:false, msg:"", btn1:"Não", btn2:"Sim", evento:"concluir"});

    const meio = "prod";

    const url = meio === "development"?"ws://localhost:8080":"wss://app.brinquedometro.com.br/ws/";
    const [socketUrl] = useState(url);
  
    const { sendMessage } = useWebSocket(socketUrl, {
        onMessage: (event) => {
            const dados = JSON.parse(event.data);
            console.log('Mensagem do servidor:', dados);
        },
        shouldReconnect: (closeEvent) => true, // Tentar reconectar em caso de falha
    });
  
  const handleSendCommand = (command, codigo) => {
    if(statusConexaoFirebase){
        const data = {
          command: command,
          id: codigo,
          milliseconds: "",
          tipoCronometro:""
      };
      sendMessage(JSON.stringify(data));
    }else{
      console.log("Sem conexão de rede!");
    }
  };


    useEffect(()=>{
        const tempos = JSON.parse(localStorage.getItem("tempos"));
        const brinquedos = JSON.parse(localStorage.getItem("brinquedos"));
        const cronometrosSalvo = JSON.parse(localStorage.getItem("Cronometros"));

        if(cronometrosSalvo){
            setListaDeCronometros([...cronometrosSalvo]);
        };

        if(tempos){
            setTemposEValores(tempos.length>0?[...listaTemposPadrao,...tempos, ...listaTempoPrsonalizada]:[...listaTemposPadrao]);
        }else{
            setTemposEValores([...listaTemposPadrao, ...listaTempoPrsonalizada]);
        };

        if(brinquedos){
            setBrinquedosLista(brinquedos.length>0?[...brinquedos]:[]);
        }

        const db = getDatabase();
        const connectedRef = ref(db, ".info/connected");
        onValue(connectedRef, (snap) => {
        if (snap.val() === true) {
            setStatusConexaoFirebase(true);
        } else {
            setStatusConexaoFirebase(false);
        }
        });

    },[]);

    function obterValoresInputados (event, id){
        if(id === "crianca"){
            setNomeCrianca(event);
        }else if(id === "responsavel"){
            setNomeResponsavel(event);
        }else if(id ==="brinquedo"){
            setBrinquedo(event);
        }else if(id ==="tempo"){
            setTempo(event);
        }else if(id === "pagamento"){
            setStatusDoPagamento(event);
        };
    };

    async function salvarDados(dados, modificarLocal) {
        try{
            const resultado = await firebaseSendDados(dados, statusConexaoFirebase);
            console.log(resultado)
            if(modificarLocal === true){
                setListaDeCronometros(prev=>{
                    prev = [...prev];
                    prev.push({...dados})
                    localStorage.setItem("Cronometros",JSON.stringify(prev));
                    return prev
                });
            };

        }catch(error){
            console.log(error)
            if(modificarLocal === true){
                setListaDeCronometros(prev=>{
                    prev = [...prev];
                    prev.push({...dados})
                    localStorage.setItem("Cronometros",JSON.stringify(prev));
                    return prev
                });
            } 
        }
    }

    function salvarCronometro (){
        if(nomeCrianca ===""){
            Notificador("","Digite o nome da criança", "warning");
        }else if(brinquedo ===""){
            Notificador("","Selecione o brinquedo", "warning");
        }else if(tempo === 0 || tempo.minutos === "Personalizado"){
            Notificador("","Selecione o tempo", "warning");
        }else if(statusDoPagamento === ""){
            Notificador("","Selecione o status do pagamento", "warning");
        }else{
            const dados = {
                crianca:nomeCrianca,
                responsavel:nomeResponsavel,
                brinquedo:brinquedo.brinquedo,
                tempo:tempo.minutos,
                tipoCronometro: tempo.minutos === "Livre"? "Livre":"decrescente",
                valorPorMin:brinquedo.valorPorMinuto,
                valorPago:tempo.minutos !== "Livre"? brinquedo.valorPorMinuto * tempo.minutos:0,
                pagamento:statusDoPagamento,
                inicio:"",
                previsaoFim:"",
                duracao: tempo.minutos !== "Livre"?parseInt(tempo.minutos)*60*1000:0,
                codigo:String(Date.now()).slice(-4),
                concluido:false,
                finalizado:false,
                cancelado:false,
                tempoLivreTotal:"",
                versao:0,
                pausado:true,
                iniciadoEm:0,
                pagamentoCancelado:false
            };
            setVisibilidadeForm(false);
            salvarDados(dados, true);
            setNomeCrianca("");
            setNomeResponsavel("");
            setBrinquedo("");
            setTempo(0);
            setStatusDoPagamento("");
            Notificador("","Cronômetro salvo com sucesso", "success"); 
        };
    };

    const formatTime = (segundos) => {
        const minutes = Math.floor(segundos / 60);
        const seconds = segundos % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      };

    function atualizarDadosMoeda (index, segundos){
        setListaDeCronometros(prev=>{
            let dadosLista = [...prev];
            if(dadosLista[index].tipoCronometro === "Livre"){
                const valorSegundo = dadosLista[index].valorPorMin/60;
                dadosLista[index].tempoLivreTotal = formatTime(segundos);
                dadosLista[index].valorPago = (segundos * valorSegundo).toFixed(2);
                localStorage.setItem("Cronometros", JSON.stringify(dadosLista));
            };
            return dadosLista;
        })
    };

    function atualizarDadosInicio (index){
        setListaDeCronometros(prev=>{
            let dadosLista = [...prev];
            const agora = Date.now();
            dadosLista[index].pausado = !dadosLista[index].pausado;
            if(dadosLista[index].inicio === ""){
                let dataStart = new Date(agora);
                let dataHora = `${String(dataStart.getDate()).padStart(2,"0")}/${String((dataStart.getMonth()) + 1).padStart(2,"0")}/${dataStart.getFullYear()} ${String(dataStart.getHours()).padStart(2,"0")}:${String(dataStart.getMinutes()).padStart(2,"0")}:${String(dataStart.getSeconds()).padStart(2,"0")}`;
                dadosLista[index].inicio = dataHora;
                dadosLista[index].iniciadoEm = agora;
                dadosLista[index].versao = dadosLista[index].versao + 1;
                if(dadosLista[index].tipoCronometro ==="decrescente"){
                    dataStart = new Date(agora + dadosLista[index].duracao);
                    dataHora = `${String(dataStart.getDate()).padStart(2,"0")}/${String((dataStart.getMonth()) + 1).padStart(2,"0")}/${dataStart.getFullYear()} ${String(dataStart.getHours()).padStart(2,"0")}:${String(dataStart.getMinutes()).padStart(2,"0")}:${String(dataStart.getSeconds()).padStart(2,"0")}`;
                    dadosLista[index].previsaoFim = dataHora;
                }else{
                    dadosLista[index].previsaoFim = "Sem previsão";
                }
            };
            localStorage.setItem("Cronometros", JSON.stringify(dadosLista));
            salvarDados(dadosLista[index], false);
            return dadosLista;
        })
    };

    function visibilidadeFormDetalhes (itemCronometro){
        setVisibilidadeFormDetalhes(!visibilidadeFormDetalhe);
        setDetalhesForm(itemCronometro);
    };

    const cronometros = listaDeCronometros.map(( item ,index)=>{
        if(item.finalizado === false){
            return(
                <ItemCronometro
                    key={index}
                    duracao={item.duracao}
                    tempoLivre={item.tempo === "Livre"?true:false}
                    segundoTempoLivre={atualizarDadosMoeda}
                    keyId={index}
                    start={atualizarDadosInicio}
                    concluido={setListaDeCronometros}
                    objetoCronometro={item}
                    abrirMenu={()=>visibilidadeFormDetalhes(index)}
                    statusRede={statusConexaoFirebase}
                />
            )
        };
    });

    function finalizar(){
        if(listaDeCronometros[detalhesForm].concluido === true){
            removerLocalStorage();
            setVisibilidadeFormDetalhes(!visibilidadeFormDetalhe);
            setListaDeCronometros(prev=>{
                let dadosLista = [...prev];
                dadosLista[detalhesForm].finalizado = true;
                dadosLista[detalhesForm].versao = dadosLista[detalhesForm].versao + 1;
                localStorage.setItem("Cronometros", JSON.stringify(dadosLista));
                salvarDados(prev[detalhesForm], false);
                return dadosLista;
            });
        }else{
            setVisibilidadeNotificacaoSelecao({visibilidade:true,msg:"O cronômetro ainda não foi concluido deseja realmente encerrar?", btn1:"Não", btn2:"Sim",evento:"concluir"})
        };
    };

    function removerLocalStorage(){
        let codigo = listaDeCronometros[detalhesForm].codigo;
        localStorage.removeItem(`remainingTime-${codigo}`);
        localStorage.removeItem(`isRunning-${codigo}`);
        localStorage.removeItem(`startTime-${codigo}`);
        localStorage.removeItem(`pausedAt-${codigo}`);
      };

      function alterarDataFim (){
            let dataStart = new Date(Date.now());
            let dataHora = `${String(dataStart.getDate()).padStart(2,"0")}/${String((dataStart.getMonth()) + 1).padStart(2,"0")}/${dataStart.getFullYear()} ${String(dataStart.getHours()).padStart(2,"0")}:${String(dataStart.getMinutes()).padStart(2,"0")}:${String(dataStart.getSeconds()).padStart(2,"0")}`;
            return dataHora;
      };

    function finalizarCronometroSemConcluido (){
        setVisibilidadeFormDetalhes(false);
        setListaDeCronometros(prev=>{
            let dadosLista = [...prev];
            if(visibilidadeNotificacaoSelecao.evento === "concluir"){
                handleSendCommand("cancel",dadosLista[detalhesForm].codigo);
                dadosLista[detalhesForm].finalizado = true;
                dadosLista[detalhesForm].pausado = true;
                dadosLista[detalhesForm].versao = dadosLista[detalhesForm].versao + 1;
                dadosLista[detalhesForm].previsaoFim = alterarDataFim();
                removerLocalStorage();
                if(dadosLista[detalhesForm].pagamento !== "Pago"){
                    setVisibilidadeNotificacaoSelecao({visibilidade:true, msg:"O cronômetro esta com status de não pago, o pagamento foi efetuado?",btn1:"Não", btn2:"Sim",evento:"pagamentoEfetuado"});
                }else{
                    setVisibilidadeNotificacaoSelecao({visibilidade:false, msg:"",btn1:"", btn2:"",evento:"concluir"});
                }
            };

            if(visibilidadeNotificacaoSelecao.evento === "pagamentoEfetuado"){
                dadosLista[detalhesForm].pagamento = "Pago";
                dadosLista[detalhesForm].versao = dadosLista[detalhesForm].versao + 1;
                setVisibilidadeNotificacaoSelecao({visibilidade:false, msg:"",btn1:"", btn2:"",evento:"concluir"});
            };

            if(visibilidadeNotificacaoSelecao.evento === "cancelar"){
                handleSendCommand("cancel",dadosLista[detalhesForm].codigo);
                dadosLista[detalhesForm].finalizado = true;
                dadosLista[detalhesForm].cancelado = true;
                dadosLista[detalhesForm].previsaoFim = alterarDataFim();
                dadosLista[detalhesForm].pausado = true;
                dadosLista[detalhesForm].versao = dadosLista[detalhesForm].versao + 1;
                removerLocalStorage();

                if(dadosLista[detalhesForm].pagamento === "Pago"){
                    setVisibilidadeNotificacaoSelecao({visibilidade:true, msg:"O cronômetro esta com status de pago, deseja cancelar o pagamento?",btn1:"Não", btn2:"Sim",evento:"cancelarPagamento"});
                }else{
                    setVisibilidadeNotificacaoSelecao({visibilidade:false, msg:"",btn1:"", btn2:"",evento:"concluir"});
                }
            };

            if(visibilidadeNotificacaoSelecao.evento === "cancelarPagamento"){
                dadosLista[detalhesForm].valorPago = 0;
                dadosLista[detalhesForm].pagamentoCancelado = true;
                dadosLista[detalhesForm].versao = dadosLista[detalhesForm].versao + 1;
                setVisibilidadeNotificacaoSelecao({visibilidade:false, msg:"",btn1:"", btn2:"",evento:"concluir"});
            };
            localStorage.setItem("Cronometros", JSON.stringify(dadosLista));
            salvarDados(dadosLista[detalhesForm], false);
            return dadosLista;
        });
    };

    return(
        <>
            <NotificadorDeSelecao
                btn1Clique={finalizarCronometroSemConcluido}
                btn2Clique={()=>setVisibilidadeNotificacaoSelecao({visibilidade:false, msg:"",btn1:"", btn2:"", evento:"concluir"})}
                visibilidade={visibilidadeNotificacaoSelecao.visibilidade}
                msg={visibilidadeNotificacaoSelecao.msg}
                btn1={visibilidadeNotificacaoSelecao.btn1}
                btn2={visibilidadeNotificacaoSelecao.btn2}
            />
            <VazioCronometros 
                eventClique={()=>setVisibilidadeForm(!visibilidadeForm)}
                notificacao={"Sem cronômetros para exibir. Para iniciar um novo clique em +"}
                visibilidade={cronometros.length === 0?true:false}
            />
            <div style={{display:"flex", flexWrap:"wrap", overflowY:"auto", maxHeight:"85vh",minWidth:"358px", justifyContent:"flex-start", marginTop:"60px", marginLeft:(isMobile && !isTablet)?(window.innerWidth - 374)/2<=0?"0px":(window.innerWidth - 374)/2:"10px"}}>
                {cronometros}
            </div>

            <FormDetalhesCronometro
                visibilidade={visibilidadeFormDetalhe}
                cronometro={listaDeCronometros[detalhesForm]}
                setVisibilidade={()=>setVisibilidadeFormDetalhes(!visibilidadeFormDetalhe)}
                finalizadoClique={finalizar}
                cancelarClique={()=>setVisibilidadeNotificacaoSelecao({visibilidade:true,msg:`O cronômetro ainda não foi concluido ${listaDeCronometros[detalhesForm].pagamento === "Pago"?"":"e não foi pago"} deseja realmente cancelar?`, btn1:"Não", btn2:"Sim",evento:"cancelar"})}
            />

            <FormNovoCronometro
                visibilidade={visibilidadeForm}
                brinquedosLista={brinquedosLista}
                listaTempos={temposEValores}
                obterValoresInputados={obterValoresInputados}
                salvarCronometro={salvarCronometro}
                setVisibilidadeForm={setVisibilidadeForm}
            />
            <BotaoAdd
                eventoDeClique={()=>setVisibilidadeForm(!visibilidadeForm)}
            />
        </>
    )
};