import icone from '../assets/icone_relogio.svg';
import img_voltar from '../assets/back.svg';
import icone_crianca from '../assets/Boy.svg';
import cash_hist from '../assets/Cash hist.svg';
import cash from '../assets/Cash.svg';
import painel from '../assets/Keypad.svg';
import pause from '../assets/Pause Squared.svg';
import qrcode from '../assets/Qr Code.svg';
import relogio from '../assets/relogio.svg';
import add from '../assets/Vector.svg';
import responsavel from '../assets/responsavel.svg';
import briquedo from '../assets/Toy Car.svg';
import iniciar from '../assets/Next.svg';
import grafico from '../assets/Combo Chart.svg';
import relogio2 from '../assets/Stopwatch.svg';
import config from '../assets/Administrative Tools.svg';
import help from '../assets/Help.svg';
import save from '../assets/Save.svg';
import remove from '../assets/Remove.svg';
import moneyBag from '../assets/Money Bag.svg';
import email from '../assets/at-sign.svg';
import cadeado from '../assets/lock.svg';
import back from '../assets/back.svg';
import back_white from '../assets/back_branco.svg';
import qrcodeScan from '../assets/qrcode_sc.svg';
import qrcode_ex from '../assets/qrcode_ex.png';
import restart from '../assets/restart.svg';
import dashboard from '../assets/dashboard.png';
import deleteItem from '../assets/delete.svg';
import plano from '../assets/plano.png';
import maleUser from '../assets/maleUser.png';
import emailContato from '../assets/Mail.png';
import lupa from '../assets/Search.png';
import telefone from '../assets/Phone.png';
import youtube from '../assets/YouTube.png';
import calendario from '../assets/Calendar.png';
import removep from '../assets/removp.svg';
import google from '../assets/Google.svg';
import facebook from '../assets/Facebook.svg';

export {
    google,
    facebook,
    removep,
    restart,
    icone, 
    img_voltar, 
    icone_crianca, 
    cash_hist, 
    cash, 
    painel, 
    pause, 
    qrcode, 
    relogio, 
    add, 
    responsavel, 
    briquedo, 
    iniciar, 
    grafico, 
    relogio2, 
    config, 
    help, 
    save, 
    remove, 
    moneyBag,
    cadeado,
    email,
    back,
    back_white,
    qrcodeScan,
    qrcode_ex,
    dashboard,
    deleteItem,
    plano,
    lupa,
    telefone,
    emailContato,
    youtube,
    maleUser,
    calendario
};

export const cores = {
    verderv:"#007E7A",
    amarelo:""
}