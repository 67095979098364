import { useEffect, useState } from "react"
import { icone_crianca, responsavel, briquedo, relogio, cash, cash_hist, iniciar, save } from "./imagens";
import FormNovoDado from "./FormNovoDado";
import Notificador from "./notificador";

export default function FormNovoCronometro({visibilidade, brinquedosLista, listaTempos, obterValoresInputados, salvarCronometro, setVisibilidadeForm}){
    const [visibilidadeFormPersonalizar, setVisibilidadeFormPersonalizar] = useState();
    const [valorPersonalizado, setValorPersonalizado] = useState();

    useEffect(()=>{
        if(valorPersonalizado){
            if(valorPersonalizado.caixa1){
                setVisibilidadeFormPersonalizar(false);
                obterValoresInputados({minutos:parseInt(valorPersonalizado.caixa1)},"tempo");
            }else{
                Notificador("","Digite os minutos personalizados!", "warning");
            }
        };
    },[valorPersonalizado]);

    return(
        visibilidade&&
        <div style={{width:"100%",height:"100%", backgroundColor:"#00000096", position:"absolute", zIndex:361, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <FormNovoDado
                visibilidade={visibilidadeFormPersonalizar}
                setVisibilidadeForm={setVisibilidadeFormPersonalizar}
                placeholder={{caixa1:"Quantidade de minutos", caixa2:"Valor cobrado por minuto", visibilidadeCaixa:"none"}}
                tipoValor={{caixa1:"number", caixa2:"number"}}
                iconeBtn={save}
                iconeInput={{caixa1:relogio, caixa2:cash}}
                titulo={"Tempo personalizado"}
                salvarClique={setValorPersonalizado}
            />
            <div style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", gap:"10px",overflowY:"auto",maxHeight:"531px",overflow:"hidden",borderRadius:"12px", paddingBottom:"15px", width:"320px",  backgroundColor:"#ffff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div style={{gap:"30px",width:"100%", height:"31px", backgroundColor:"#007E7A", border:"none", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    <header style={{textAlign:"center",color:"#ffff", fontWeight:"500", fontSize:"18px"}}>Novo cronômetro</header>
                    <span onClick={()=>setVisibilidadeForm(false)} style={{color:"red", fontSize:"22px", fontWeight:"700", width:"40px",cursor:"pointer"}}>X</span>
                </div>
                <CaixaTexto
                    listaBrinquedos={brinquedosLista}
                    obterValoresInputados={obterValoresInputados}
                />
                <SelectTempoValor
                    listaTempos={listaTempos}
                    obterValoresInputados={obterValoresInputados}
                    personalizado={setVisibilidadeFormPersonalizar}
                />
                <div onClick={salvarCronometro} style={{cursor:"pointer",gap:"15px",backgroundColor:"#007E7A", width:"170px", height:"45px", borderRadius:"18px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    <img src={iniciar} alt="icone input" style={{width:"30px", height:"30px"}} />
                    <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Savar</h6>
                </div>
            </div>
    </div>
    )
};


function CaixaTexto ({listaBrinquedos, obterValoresInputados}){
    const [brinqudeoDefult, setBrinquedoDefult] = useState("Selecione o brinquedo");
    return(
        <>
            <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px"}}>
                <img src={icone_crianca} alt="icone input" style={{width:"30px", height:"30px"}} />
                <input onChange={(e)=>obterValoresInputados(e.target.value, "crianca")} type={"text"} placeholder={"Nome"} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
            </div>

            <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px"}}>
                <img src={responsavel} alt="icone input" style={{width:"27px", height:"27px"}} />
                <input onChange={(e)=>obterValoresInputados(e.target.value, "responsavel")} type={"text"} placeholder={"Nome do responsável"} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
            </div>

            <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px"}}>
                <img src={briquedo} alt="icone input" style={{width:"30px", height:"30px"}} />
                <select 
                    value={brinqudeoDefult} 
                    onChange={(e)=>{
                    obterValoresInputados(listaBrinquedos[e.target.value], "brinquedo");
                    setBrinquedoDefult(listaBrinquedos[e.target.value].brinquedo);
                }}
                    style={{border:"none", outline:"none", width:"80%", backgroundColor:"#ffff", color:"#797777"}} 
                >
                    <option value={brinqudeoDefult} disabled hidden>{brinqudeoDefult}</option>
                    {listaBrinquedos.map((brinquedoNome, index)=>{
                        return(
                            <option key={index} value={index}>{brinquedoNome.brinquedo}</option>
                        )
                    })}
                </select>
            </div>
        </>
    )
};

function SelectTempoValor ({listaTempos, obterValoresInputados, personalizado}){
    const [corFundo, setCorFundo] = useState(null)
    const [corFundoBtnStatusPagamento, setCorFundoBtnStatusPagamento] = useState();

    function obterValorTempo(index, valor){
        if(valor.minutos === "Personalizado"){
            personalizado(true);
        };
        obterValoresInputados(valor,"tempo");
        setCorFundo(index);
    };

    function obterValorPagemanto(event){
        setCorFundoBtnStatusPagamento(event);
        obterValoresInputados(event,"pagamento");
    };

    return(
        <>
            <div style={{gap:"10px",display:"flex",flexDirection:"column", width:"90%", heigth:"116px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px", alignContent:"center", alignItems:"start"}}>
                <h4 style={{fontSize:"15px", fontWeight:"500", margin:"0", color:"#797777"}}>Selecione o tempo: </h4>
                <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"95%", borderRadius:"10px", padding:"5px", overflowX:"auto",scrollbarWidth:"none"}}>

                    {
                        listaTempos.map((valor,index)=>{
                            let tipo = typeof valor.minutos;
                            tipo = tipo === "number"?true:false;
                            return(
                                <div key={index} onClick={()=>obterValorTempo(index, valor)} style={corFundo === index?{width:"140px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#007E7A",padding:"5px"}:{width:"140px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#FFCA3C", padding:"5px"}}>
                                    <img src={relogio} alt="icone input" style={{pointerEvents:"none",width:"35px", height:"35px"}} />
                                    <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>{tipo?`${valor.minutos}:00`:valor.minutos}</h6>
                                </div>
                            )
                        })
                    }
                    
                    </div>
            </div>

            <div style={{gap:"10px",display:"flex",flexDirection:"column", width:"90%", heigth:"116px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px", alignContent:"center", alignItems:"start"}}>
            <h4 style={{fontSize:"15px", fontWeight:"500", margin:"0", color:"#797777"}}>Selecione status do pagamento: </h4>
            <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"95%", borderRadius:"10px", padding:"5px", overflowX:"auto"}}>

                <div style={corFundoBtnStatusPagamento == "Pago" ? {width:"73px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#007E7A"}:{width:"73px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#EDB111"}}>
                    <img id="1" onClick={()=>obterValorPagemanto("Pago")} src={cash} alt="icone input" style={{width:"35px", height:"35px"}} />
                    <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Pago</h6>
                </div>

                <div style={corFundoBtnStatusPagamento == "Não pago" ? {width:"73px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#007E7A"}:{width:"73px", height:"64px", borderRadius:"5px", cursor:"pointer", display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center", gap:"5px", backgroundColor:"#EDB111"}}>
                    <img id="2" onClick={()=>obterValorPagemanto("Não pago")} src={cash_hist} alt="icone input" style={{width:"35px", height:"35px"}} />
                    <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Não pago</h6>
                </div>
            </div>
        </div>
        </>   
    )
};

