import { HeaderSuperior } from "../../componentes/painel_header";
import { useNavigate } from "react-router-dom";
import { lupa, telefone, youtube } from "../../componentes/imagens";
import '../ajuda/ajuda.css';
import React from "react";
import PropTypes from 'prop-types';

import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from '../../componentes/firebase/configuracoes';

export default function Ajuda() {
    const navigate = useNavigate();
    const [video, setVideo] = React.useState([{ link: "", img: "", descricao: "", titulo: "" }, false]);
    const [listaInicial, setListaInicial] = React.useState();
    const [lista, setLista] = React.useState(listaInicial !==undefined ? listaInicial : []);
 
    const getVideoId = (url) => {
        const urlObj = new URL(url);
        return urlObj.searchParams.get("v");
    };

    React.useEffect(()=>{
        if(listaInicial){
            setLista(listaInicial.Videos);
        };
    }, [listaInicial])

    React.useEffect(()=>{
        const db = getDatabase(app);
            const dbHelp = ref(db, "ConfiguracoesGlobais");
            onValue(dbHelp, (snapshot) => {
                let videosHelp = snapshot.val();
                setListaInicial(videosHelp);
            });
    },[]);

    function helpWhatsapp (){
        if(listaInicial !== undefined){
            const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
            const phoneNumber = listaInicial.Contato.Numero.Tel.replace("(","").replace(")","").replace(" ","");
            const message = 'Olá, estou com dúvidas sobre o uso do brinquedometro! Meu e-mail de usuário: ' + dadosUsuario.email; // Mensagem a ser enviada
            const encodedMessage = encodeURIComponent(message);
            const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
            window.open(url, '_blank');
        }

    }

    return (
        <div style={{backgroundColor: video[1]? "black" : null, width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <HeaderSuperior titulo={"Ajuda"} eventClique={video[1] ?() => setVideo([{ link: "", img: "", descricao: "", titulo: "" }, false]) : () =>  navigate("/home")} />
            <div style={{ gap: "5px", maxWidth: "600px", width: "95%", height: "calc(100% - 70px)", marginTop: "70px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                <div style={{ padding: "5px" }}>
                    <div style={{ overflow: "hidden", padding: "5px", borderRadius: "50px", backgroundColor: "#ffff", alignItems: "center", display: "flex" }}>
                        <img style={{ padding: "4px" }} src={lupa} alt="pesquisar" />
                        <input onChange={(event)=>setLista(listaInicial.Videos.filter(lista=>lista.descricao.toLowerCase().includes(event.target.value.toLowerCase())))} style={{ width: "300px", height: "35px", color: "black" }} type="search" placeholder="Pesquisar por ajuda" />
                    </div>
                </div>
                <div onClick={helpWhatsapp} style={{cursor:"pointer", padding: "5px", borderRadius: "10px", backgroundColor: "#ffff" }}>
                    <p style={{ paddingLeft: "4px", fontWeight: "600" }}>Entre em contato clicando aqui</p>
                    <div style={{ alignItems: "center", display: "flex", padding: "1px" }}>
                        <img style={{ padding: "4px" }} src={telefone} alt="telefone" />
                        <p style={{ width: "300px" }}>{listaInicial !== undefined? listaInicial.Contato.Numero.Tel : ""}</p>
                    </div>
                </div>
                
                {video[1] && (
                    <div style={{backgroundColor:"#000000b0",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",width:"100%", height:"calc(100% - 70px)", position:"absolute", zIndex:1000}}>
                        <iframe
                            style={{ width: "100%", height: "100%", border: "none" }}
                            src={`https://www.youtube.com/embed/${getVideoId(video[0].link)}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media"
                            allowFullScreen
                        />
                    </div>
                )}
                
                <div style={{scrollbarWidth:"none", overflowY: "auto", gap: "5px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", minHeight: "60%" }}>
                    {
                        lista.map((item, index) => (
                            <CardVideo key={index} item={item} clique={setVideo} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

function CardVideo({ clique, item }) {
    return (
        <div style={{ width: "340px", padding: "5px", backgroundColor: "#ffff", borderRadius: "10px" }}>
            <div style={{ gap: "10px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <div style={{ backgroundColor: "black", width: "130px", borderRadius: "10px", height: "100px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img onClick={() => clique([{ ...item }, true])} className="imgPlayer" src={youtube} alt={"video youtube"} style={{ cursor: "pointer" }} />
                </div>
                <div style={{ width: "200px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <h2 style={{ margin: "0", color: "gray", fontSize: "16px", fontWeight: "600" }}>{item.titulo}</h2>
                    <p style={{ textAlign: "justify", fontSize: "15px", fontWeight: "500" }}>{item.descricao}</p>
                </div>
            </div>
        </div>
    );
}

CardVideo.propTypes = {
    clque: PropTypes.func,
    item: PropTypes.object
  };