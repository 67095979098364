import {app} from './configuracoes';
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import handleFirebaseError from './erros';
import Notificador from '../notificador';

export default function resetSenhaEmail(email, setnotificacaoPerson, activeIndicador){
    const auth = getAuth(app);
    activeIndicador(true);
    sendPasswordResetEmail(auth, email)
        .then((resposta)=>{
            setnotificacaoPerson(`Um e-mail para recuperação de conta foi enviado para ${email}. Por favor, abra seu e-mail e clique no link.`);
            activeIndicador(false);
        })
        .catch((error)=>{
            Notificador("Algo deu errado", handleFirebaseError(error),"warning");
            activeIndicador(false);
        });
};