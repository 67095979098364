import { useEffect, useState } from "react";
import Notificador from "./notificador";

export default function FormNovoDado({visibilidade, setVisibilidadeForm, placeholder, tipoValor, salvarClique, iconeInput, iconeBtn, titulo}){

    const [caixa1, setCaixa1] = useState();
    const [caixa2, setCaixa2] = useState();

    useEffect(()=>{
        setCaixa1(undefined);
        setCaixa2(undefined)
    },[visibilidade]);

    function validar (){
        if(placeholder.visibilidadeCaixa === "flex"){
            if(caixa1 && caixa2){
                return {caixa1:caixa1, caixa2:caixa2}
            }else{
                Notificador("Digite todos os valores solicitados!", "", "warning");
            };
        }
        if(placeholder.visibilidadeCaixa === "none"){
            if(caixa1){
                return {caixa1:caixa1, caixa2:caixa2}
            }else{
                Notificador("Digite a quantidade de minutos!", "", "warning");
            };
        }

    };
    
    return(
        visibilidade&&
        <div style={{width:"100%",height:"80%", position:"absolute", zIndex:361, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingTop:"50px"}}>
            <div style={{ boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", gap:"10px",overflow:"hidden",borderRadius:"12px", paddingBottom:"15px", width:"300px",  backgroundColor:"#ffff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div style={{gap:"30px",width:"100%", height:"31px", backgroundColor:"#007E7A", border:"none", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    <header style={{textAlign:"center",color:"#ffff", fontWeight:"500", fontSize:"18px"}}>{titulo}</header>
                    <span onClick={()=>{
                        setVisibilidadeForm(false);
                    }} style={{color:"red", fontSize:"22px", fontWeight:"700", width:"40px", cursor:"pointer"}}>X</span>
                </div>
                <div style={{gap:"10px",display:"flex",flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px"}}>
                    <img src={iconeInput.caixa1} alt="icone input" style={{width:"30px", height:"30px"}} />
                    <input onChange={(e)=>setCaixa1(e.target.value)} type={tipoValor.caixa1}  placeholder={placeholder.caixa1} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
                </div>

                <div style={{gap:"10px",display:placeholder.visibilidadeCaixa,flexDirection:"row", width:"90%",heigth:"45px", border:"solid 1px #9F9FA0", borderRadius:"10px", padding:"5px"}}>
                    <img src={iconeInput.caixa2} alt="icone input" style={{width:"30px", height:"30px"}} />
                    <input onChange={(e)=>setCaixa2(e.target.value)} type={tipoValor.caixa2} placeholder={placeholder.caixa2} style={{color:"#797777" ,border:"none", outline:"none", width:"80%"}}/>
                </div>
                
                <div onClick={()=>{
                    salvarClique(validar());
                }} style={{cursor:"pointer",gap:"15px",backgroundColor:"#007E7A", width:"170px", height:"45px", borderRadius:"18px", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                    <img src={iconeBtn} alt="icone input" style={{width:"30px", height:"30px"}} />
                    <h6 style={{pointerEvents:"none",fontSize:"15px", fontWeight:"500", margin:"0", color:"#fff"}}>Savar</h6>
                </div>
            </div>
        </div>
    )
};