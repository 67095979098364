import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import "../estilos.css";
import formatMoeda from './formataMoeda';
import dataText from '../../../componentes/dataComoText';


export default function Card({cor, valor, titulo, icone, clique, moeda, datas}) {
    const [cardWidth, setCardWidth] = useState(isMobile ? window.innerWidth - 38 : 300);
    const [data, setData] = useState(datas);

    useEffect(() => {
        const handleResize = () => {
            if (isMobile) {
                setCardWidth(window.innerWidth - 30);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        setData({...datas});
    }, [datas])

    return (
        <div
            className='cards'
            onClick={clique}
            style={{
                cursor:"pointer",
                display:"flex",
                alignItems:"center",
                width: `${cardWidth}px`,
                height: "120px",
                borderRadius: "10px",
            }}>
                <div style={{pointerEvents:"none",boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",gap:"20px",display:"flex",flexDirection:"row", alignItems:"center", justifyContent:"center", width: `${cardWidth}px`, height: "100px", backgroundColor:cor,borderRadius: "10px", }}>
                    {icone}
                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                        <h1 style={{margin:"0",color:"#ffff", fontWeight:"700", fontSize:"35px"}}>{moeda ? formatMoeda(Number(valor)) : valor}</h1>
                        <h2 style={{fontFamily:"Segoe UI', Tahoma, Geneva, Verdana, sans-serif", margin:"0", color:"#ffff", fontWeight:"600", width:"100%", textAlign:"center"}}>{titulo}</h2>
                        {isMobile&&<CardDate valor={data} />}
                    </div>
                </div>
        </div>
    )
}

const estilo = {
    display:"flex",
    flexDirection:"row", 
    gap:"10px", 
    alignItems:"center", 
    justifyContent:"center", 
    fontSize:"14px",
    cursor:"pointer"
  };

function CardDate ({valor}){
    return(
      <div style={{...estilo, paddingLeft:"10px", paddingRight:"10px"}}>
        <div style={estilo}>
          <CalendarIcon cor='#ffff' />
          <p style={{...estilo, color:"#ffff"}}>{dataText(valor.startDate)}</p>
        </div>
          <p style={{...estilo, color:"#ffff"}}>até</p>
          <div style={estilo}>
            <CalendarIcon cor='#ffff' />
            <p style={{...estilo, color:"#ffff"}}>{dataText(valor.endDate)}</p>
          </div>
      </div>
    )
  }

  function CalendarIcon({cor = "#727272", clique}) {
    return (
      <svg
        onClick={clique}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke={cor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 2v4" />
        <path d="M16 2v4" />
        <rect width="18" height="18" x="3" y="4" rx="2" />
        <path d="M3 10h18" />
      </svg>
    )
  };
  
  


