import { getDatabase, ref, set } from "firebase/database";
import { app } from "./configuracoes";

export default function primeiroAcesso (uid, email, nome){
  const dados = {
    "uid": uid,
    "Acesso": true,
    "EndPlano": Date.now() + 15 * 24 * 60 * 1000,
    "Nome": nome,
    "email":email,
    "Plano": "Teste grátis",
    "dataCadastro": Date.now(),
    "telefone": ""
  };

  const db = getDatabase(app);
  set(ref(db, "Usuarios/"+ uid), dados)
    .then(dados=>{})
    .catch(error=>{})      
};
  

