export default function dataValor() {
    function formatData (dataV){
        let result = dataV;
        if(dataV<10){
            result = `0${dataV}`;
        }
        return result;
    }

    let data = new Date(Date.now());
    data = `${formatData(data.getDate())}/${formatData(data.getMonth()+1)}/${data.getFullYear()}`;
    return data
};