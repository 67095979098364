import {useState } from "react";
import { img_voltar, painel, relogio, cash_hist } from "./imagens";

export function HeaderSuperior ({titulo, eventClique, child}){
    return(
        <header style={{ position:"fixed",width:"100%", height:"58px", backgroundColor:"#FFFFFF", alignItems:"center", justifyContent:"center", display:"flex", borderBottomRightRadius:"20px", borderBottomLeftRadius:"20px"}}>
            <img onClick={eventClique} style={{cursor:"pointer"}} src={img_voltar} alt="Botão voltar"/>
            <div style={{display:"flex", width:"80%", justifyContent:"center"}}><h1 style={{pointerEvents:"none", margin:"0", color:"#007E7A", fontSize:"22px",fontFamily:"Segoe UI', Tahoma, Geneva, Verdana, sans-serif"}}>{titulo}</h1></div>
            {child}
        </header>
    )
};


export function HeaderInferior ({eventoDeClique}){
    const [botaoClicado, setBotaoClicado]= useState();

    function cliqueBtn (id){
        eventoDeClique(id);
        setBotaoClicado(id);
    };

    return(
        <header style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", position:"fixed", bottom:"0px",width:"100%", height:"60px", backgroundColor:"#FFFFFF", alignItems:"center", justifyContent:"space-around", display:"flex", borderTopRightRadius:"20px", borderTopLeftRadius:"20px"}}>
            <Botao
                id={"Cronômetros"}
                icone={painel}
                nome={"Cronômetros"}
                cor={botaoClicado?botaoClicado === "Cronômetros"?"#EDB111":"#007E7A":"#EDB111"}
                eventClique={cliqueBtn}
            />
            <Botao
                id={"Finalizados"}
                icone={relogio}
                nome={"Finalizados"}
                cor={botaoClicado === "Finalizados"?"#EDB111":"#007E7A"}
                eventClique={cliqueBtn}
            />
            <Botao
                id={"Ganhos"}
                icone={cash_hist}
                nome={"Ganhos"}
                cor={botaoClicado === "Ganhos"?"#EDB111":"#007E7A"}
                eventClique={cliqueBtn}
            />
        </header>
    )
};

function Botao ({nome, cor, icone, eventClique, id}){
    return(
        <div id={id} onClick={()=>eventClique(id)} style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor:"pointer",flexDirection:"column",display:"flex", width:"98px", height:"49px",alignItems:"center", justifyContent:"center", backgroundColor:cor, borderRadius:"10px"}}>
                <img style={{cursor:"pointer", width:"25px", height:"25px"}} src={icone} alt="icone painel"/>
                <h4 style={{fontFamily:"Segoe UI', Tahoma, Geneva, Verdana, sans-serif",pointerEvents:"none", margin:"0px", fontSize:"15px", fontWeight:"600", color:"#ffff"}}>{nome}</h4>
        </div>
    )
};