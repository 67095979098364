import { Store } from 'react-notifications-component';

export default function Notificador (titulo, msg, tipo = "warning"){
    Store.addNotification({
        title: titulo,
        message: msg,
        type: tipo,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 7000,
          onScreen: true
        }
      });
};