import { useEffect, useState } from "react";
import { getDatabase, ref, onValue, } from "firebase/database";
import { app } from './firebase/configuracoes';

const styleDiv = {display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"5px", width:"100%"};

export default function CardGanhos({totalList, header, calendario, dataSelecionada}) {

  // const [data, setData] = useState(dataSelecionada !== undefined?dataSelecionada:returnData());
  const [listaRegistros, setListaRegistros] = useState([]);
  const [totalRecebido, setTotalRecebido] = useState(0);

  useEffect(()=>{
    const dadosUsuario = JSON.parse(localStorage.getItem("usuarioLogado"));
    if(header === true){
        const db = getDatabase(app);
        const cronometro = ref(db, "DadosUsuarios/" + dadosUsuario.uid + "/Registros");
        onValue(cronometro, (snapshot) => {
            let cronometroList = snapshot.val();
            if(cronometroList){
              setListaRegistros(Object.entries(cronometroList).filter(([key, lista])=> lista.inicio.includes(dataSelecionada) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado))
            }
        });
    }else{
        if(totalList.length>0){
            setListaRegistros(totalList.filter((lista)=> lista.inicio.includes(dataSelecionada) && (lista.concluido || lista.finalizado) && !lista.pagamentoCancelado))
        }
    }
},[dataSelecionada, totalList]);

  
  useEffect(()=>{
    let total = 0;
    if(header){
        listaRegistros.forEach(([key, item])=> {
          total = item.tipoCronometro === "Livre"?Number(item.valorPago) + total : item.valorPago + total;
        });
    }else{
      listaRegistros.forEach((item)=> {
        total = item.tipoCronometro === "Livre"?Number(item.valorPago) + total : item.valorPago + total;
      });
    }
    setTotalRecebido(total);
  }, [listaRegistros]);

  return (
    <div style={{pointerEvents:"none",maxWidth:"400px", width:"95%",boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"2px", backgroundColor:"#ffff", overflow:"hidden",borderRadius:"8px"}}>
        <div style={{...styleDiv, flexDirection:"column"}}>
            <div style={{...styleDiv, backgroundColor:"#4A9947",pointerEvents:"all",}}>
                <DollarSignIcon cor={"#ffff"}/>
                <h1 style={{color:"#ffff"}}>Total de Ganhos</h1>
                {
                  header && 
                  <div style={{backgroundColor:"#ffff",marginLeft:"30px",borderRadius:"8px",padding:"6px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <CalendarIcon clique={()=>calendario(true)} />
                  </div>
                }
            </div>
            <div style={{...styleDiv, gap:"15px"}}>
                <DollarSignIcon cor={"#4A9947"}/>
                <h1 style={{color:"#4A9968", fontSize:"64px", margin:"5px"}}>{`${String(totalRecebido.toFixed(2)).replace(".",",")}`}</h1>
                <TrendingUpIcon/>
            </div>
            <div style={{...styleDiv, backgroundColor:"#f4f4f4"}}>
                <CalendarIcon/>
                <h1 style={{color:"#727272", fontWeight:"500", fontSize:"12px"}}>{`Resultado do dia ${dataSelecionada}`}</h1>
            </div>
            
        </div>
    </div>
  )
}

function CalendarIcon({cor = "#727272", clique}) {
  return (
    <svg
      onClick={clique}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke={cor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
    </svg>
  )
}


function DollarSignIcon({cor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={cor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="12" x2="12" y1="2" y2="22" />
      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
    </svg>
  )
}

function TrendingUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#4A9937"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
      <polyline points="16 7 22 7 22 13" />
    </svg>
  )
}