import {Routes, Route} from 'react-router-dom';
import Home from '../paginas/home/Home';
import Cronometro from '../paginas/cronometro/Cronometro';
import TemposValores from '../paginas/temposValores/TempoValores';
import Login from '../paginas/login/Login';
import Time from '../paginas/time/time';
import Ganhos from './Ganhos';
import Dashboard from '../paginas/dashboard/dashboard';
import Ajuda from '../paginas/ajuda/Ajuda';
import Configuracao from '../paginas/configuracao/Configuracao';

export default function Rotas() {
    return (
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="home" element={<Home/>}/>
        <Route path="cronometro" element={<Cronometro/>}/>
        <Route path="tempo" element={<TemposValores/>}/>
        <Route path='time' element={<Time/>}/>
        <Route path='ganhos' element = {<Ganhos header={true}/>}/>
        <Route path='painel' element = {<Dashboard/>}/>
        <Route path='ajuda' element = {<Ajuda/>}/>
        <Route path='conta' element = {<Configuracao/>}/>
      </Routes>
    );
}