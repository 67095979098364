export default function NotificacaoPersonalizada({texto, cor, icone, visibilidade, clique}){

    return(
        visibilidade&&
        <div style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px;x",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"100%", height:"100%", position:"absolute", zIndex:1}}>
            <div style={{padding:"10px",gap:"10px",borderRadius:"20px",width:"350px",backgroundColor:cor, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <img src={icone} alt="imagem notificacao"/>
                <p style={{textAlign:"justify", color:"#ffff"}}>{texto}</p>
                <button onClick={clique}>Entendi</button>
            </div>
        </div>
    )
};